<app-order-optionbar [filters]="orderFilters" (filterSelected)="onFilterSelected($event)"
        (reset)="optionBarFilterReset($event)">
</app-order-optionbar>

<div class="orders-component-container  page-root-container">
        <div class="page-header">
                <div class="page-title"><img class="icon" src="assets/images/menu/svg/orders.svg"><span class="title">Order</span></div>

                <div class="page-actions">
                        <!-- <button mat-button color="primary" class="btn-add"
                            (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                         {{ element.deliveryDate + 'T' + element.deliveryTime | date:'MMM d, y,
                                                h:mm a' }} -->
                                                @if(permission.can_export){
                        <button mat-button color="accent" class="btn-export" (click)="directToExportOrder()">
                                <img class="download-icon" [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">Export</button>
                        }
                </div>

                
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <app-search-filter #searchFilter  [pagingData]="pagingData"
                        [pageSizeOptions]="[5,10,20,50,100]"  [filter]="filter"
                        (page)="onePageEvent($event)" [module]="'order'" (onApply)="onFilterApplied($event)"
                        [hidePageSize]="true">
                </app-search-filter>
         @if(orderSummary){
                <app-order-summary-card [data]="orderSummary"></app-order-summary-card>
         }
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource">                    
                                <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef>#</th>
                                        <td mat-cell *matCellDef="let element; let i = index">{{
                                                (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container>
                                <ng-container matColumnDef="taxInvoiceNo">
                                        <th mat-sort-header="order_hdr_ext.tax_invoice_no" mat-header-cell
                                                *matHeaderCellDef (click)="sortData($event)"> Tax Invoice No. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.taxInvoiceNo}} </td>
                                </ng-container>
                                <ng-container matColumnDef="shopName">
                                        <th mat-sort-header="v_oo_shop.name" (click)="sortData($event)" mat-header-cell
                                                *matHeaderCellDef> Shop
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.shopName}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="customerName">
                                        <th mat-sort-header="customerName" mat-header-cell (click)="sortData($event)"
                                                *matHeaderCellDef>Customer
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.customerName}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="totalAmount">
                                        <th mat-sort-header="order_hdrs.total_amount" mat-header-cell
                                                (click)="sortData($event)" *matHeaderCellDef class="amount-header">
                                                Amount
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="order-amt" style="padding-right:22px !important;"> {{element.totalAmount
                                                | currency }} </td>
                                </ng-container>
                                <ng-container matColumnDef="orderTime">
                                        <th mat-sort-header="order_date_time" mat-header-cell (click)="sortData($event)"
                                                *matHeaderCellDef> Order
                                                Time
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.orderTime | date:dateFormat }}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="deliveryTime">
                                        <th mat-sort-header="delivery_date_time" mat-header-cell
                                                (click)="sortData($event)" *matHeaderCellDef>
                                                Delivery Time
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                                {{ element.deliveryDate + 'T' + element.deliveryTime | date:dateFormat
                                                }} </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                        <th mat-sort-header="displayStatus" (click)="sortData($event)" mat-header-cell
                                                *matHeaderCellDef class="status-header"> Status </th>
                                        <td mat-cell *matCellDef="let element" class="center "> <span
                                                        class="status-cell order-{{element.order_status}}">
                                                        {{ element.order_status | orderStatus }} </span> </td>
                                </ng-container>





                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row" *matRowDef="let order; columns: displayedColumns;"
                                        (click)="actionOrderPageNav(order)">
                                </tr>
                        </table>
                </section>
                @if(pagingData.length<=0){ <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
        </div>
        }
</div>

</div>