import { Component } from '@angular/core';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrl: './access-denied.component.scss',
    standalone: false
})
export class AccessDeniedComponent {

}
