import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dashboard-edit',
    templateUrl: './dashboard-edit.component.html',
    styleUrls: ['./dashboard-edit.component.scss'],
    standalone: false
})
export class DashboardEditComponent {

  editForm!: FormGroup;

  constructor(private builder: FormBuilder,
    public dialogRef: MatDialogRef<DashboardEditComponent>,
    @Inject(MAT_DIALOG_DATA) public item: any
  ) {
    this.editForm = this.builder.group({
      title: this.builder.control('', Validators.required),
      content: this.builder.control('', Validators.required),
      active:this.builder.control('', Validators.required)
    });

  }

  save(): void {

    if(this.editForm.valid)
      this.dialogRef.close(this.item);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getTitle():string{
    let title=(this.item.id==undefined)?"New":"Edit : "+this.item.title;
    return title;
  }
}
