import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { OrdersService } from './orders.service';
import { OrderModel } from './models/orders.model';
import { OrderViewComponent } from "./order-view/order-view.component";
import { OrderExportComponent } from "./order-export/order-export.component";
import { formatDate } from '@angular/common';
import { AuthService } from "src/app/auth/auth.service";
import { FilterService } from "src/app/common/components/search-filter/filter.service";
import { AppConstants } from "src/app/common/app.constants";
import { SystemSettingService } from "src/app/common/services/system-setting.service";
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from "src/app/auth/permission.service";

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.scss',
    standalone: false
})
export class OrdersComponent implements AfterViewInit {
  dateFormat: string = 'M/d/yy, h:mm a';
  orderList: OrderModel[]=[];
  favoriteFilters: any;
  shopList: any = [];
  statusList: any = [];
  showSearch: boolean = false;
  isLoading = true;
  yesterdayColumnFilter: any;
  orderExportDataOption: any[] = [{ label: 'Order Summary', value: 'all' }, { label: 'Item List', value: 'items' }];
  selectedValue: any = this.orderExportDataOption[0].value;
  // public pageLoading: boolean = false;
  bodyParam: any;
  totalCount: any;
  

  filter: FilterListItemModel[] = [
    {
      title: "Shop Name",
      column: "v_oo_shop.shop_id",
      type: "list",
      values: []
    },
    {
      title: "Customer Name",
      column: "CONCAT_WS('',order_customer.first_name, ' ', order_customer.last_name )",
    },
    {
      title: "Tax Invoice No",
      column: "order_hdr_ext.tax_invoice_no",
    },

    {
      title: "Total Amount",
      column: "total_amount",
      type: "number",
      operators: [
        { title: "is", operator: "=" },
        { title: ">=", operator: ">=" },
        { title: "<=", operator: "<=" },

      ],

    },
    {
      title: "Order Date",
      column: "order_hdrs.order_date",
      type: "date",
      operators: [
        { title: "is", operator: "=" },
        { title: ">=", operator: ">=" },
        { title: "<=", operator: "<=" },

      ],
    },
    {
      title: "Delivery Date",
      column: "order_hdr_ext.delivery_date",
      type: "date",
      operators: [
        { title: "is", operator: "=" },
        { title: ">=", operator: ">=" },
        { title: "<=", operator: "<=" },

      ],
    },
    {
      title: "Status",
      column: "order_status",
      type: "list",
      values: []
    },
  ];

  public displayedColumns: string[] = [
    "item",
    "taxInvoiceNo",
    "shopName",
    "customerName",
    "totalAmount",
    "orderTime",
    "deliveryTime",
    "status"
  ];

  pagingData = { length: 0, pageSize: 50, pageIndex: 0 };

  isHandset: boolean = false;
  filerList = [{
    "id": "",
    "user_id": "",
    "module": "order",
    "name": "",
    "is_favorite": 1,
    "filter": "",
    "filter_cnt": 0
  }]
  defaultColumnFilter = [{ column: "order_hdrs.order_date", operator: "=", value: this.formatDate(new Date()) , type: "date"  }];
  //yesterdayColumnFilter = [{ column: "", operator: "=", value: "", type: "date" }];

  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [{ column: "delivery_date_time", order: "desc" }];
  selectedItem?: OrderModel;
  dataSource = new MatTableDataSource(this.orderList);
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  orderFilters: any[];
  orderSummary: any;
  IsDownloading = false;
  permission: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private orderService: OrdersService,
    private authService: AuthService,
    private filterService: FilterService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router,
    private systemSettingService: SystemSettingService,
    private stateManagerService: StateManagerService,
  private permissionService:PermissionService) {
    // console.log(formatDate(new Date(), 'yyyyMMddhh:mm:ss', 'en-US', '+0530')) ;
    //  console.log(this.formatDate(new Date()));

    this.orderList = [];
    this.orderFilters = this.getPreDefinedFilters();
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.permission = permissionService.getPagePermission('orders');

  }


  /**
  * initilaize the device model
  */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });

    this.loadshopList();
    this.loadstatusList();
    this.loadFavoriteFilters();
    //this.setSystemParam();

    
  }
    /** 
  * Set the datasource
  */
    setDataSource(orderList:OrderModel[]){
      this.dataSource=new MatTableDataSource(orderList);
      this.dataSource.sort=this.sort;
    }
  
    @ViewChild(MatSort) sort!: MatSort;
  ngAfterViewInit(): void {
    this.loadPreviousState();
  
  }
  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter,true);

    } else {
      this.setSortColumn('order_status','asc');
      //this.loadData();
        if (this.searchFilter != undefined) {

      this.defaultColumnFilter[0].value = this.formatDate(new Date()); //this.formatDate(new Date());//'2024-05-07';
      this.searchFilter.setColumnFilter(this.defaultColumnFilter);
    }
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }

  getPreDefinedFilters(): any[] {


    let todayFilter = [{ column: "order_hdrs.order_date", operator: "=", value: this.formatDate(new Date()) , type: "date" }];
    let yesterdayFilter = [{ column: "order_hdrs.order_date", operator: "=", value: "" , type: "date" }];
    let thisWeekFilter = [{ column: "order_hdrs.order_date", operator: ">=", value: "" , type: "date" }];
    let lastWeekFilter = [{ column: "order_hdrs.order_date", operator: ">=", value: "" , type: "date" }, { column: "order_hdrs.order_date", operator: "<=", value: "" , type: "date" }];
    let thisMonthFilter = [{ column: "order_hdrs.order_date", operator: ">=", value: "" , type: "date" }];



    var date = new Date();
    date.setDate(date.getDate() - 1);
    yesterdayFilter[0].value = this.formatDate(date);


    var weekDay = date.getDay();
    date.setDate(date.getDate() - (weekDay - 1));
    thisWeekFilter[0].value = this.formatDate(date);


    var dateTo = new Date();
    var dateFrom = new Date();
    weekDay = dateTo.getDay();
    dateTo.setDate((dateTo.getDate() - (weekDay - 1)) - 1);
    dateFrom.setDate((dateFrom.getDate() - (weekDay - 1)) - 7);
    lastWeekFilter[0].value = this.formatDate(dateFrom);
    lastWeekFilter[1].value = this.formatDate(dateTo);



    date = new Date();
    date.setDate(1);
    thisMonthFilter[0].value = this.formatDate(date);
    return [
      {
        id: 100001,
        user_id: 1,
        module: "order",
        name: "Today",
        is_favorite: 1,
        filter: JSON.stringify(todayFilter),
        filter_cnt: 1
      },
      {
        id: 100002,
        user_id: 1,
        module: "order",
        name: "Yesterday",
        is_favorite: 1,
        filter: JSON.stringify(yesterdayFilter),
        filter_cnt: 1
      },
      {
        id: 100003,
        user_id: 1,
        module: "order",
        name: "This Week",
        is_favorite: 1,
        filter: JSON.stringify(thisWeekFilter),
        filter_cnt: 1
      },
      {
        id: 100004,
        user_id: 1,
        module: "order",
        name: "Last Week",
        is_favorite: 1,
        filter: JSON.stringify(lastWeekFilter),
        filter_cnt: 2
      },
      {
        id: 100005,
        user_id: 1,
        module: "order",
        name: "This Month",
        is_favorite: 1,
        filter: JSON.stringify(thisMonthFilter),
        filter_cnt: 1
      }

    ];


  }


  /**
     * loads all shops
     */
  // loadshopList(): void {

  //   this.orderService.getShopList().subscribe(
  //     (response: any) => {
  //       if (response.successCode === 0) {
  //         this.shopList = response.data;
  //         this.filter[0].values = (this.shopList.map((shop: any) => {
  //           return { title: shop.name, value: shop.id };
  //         }));
  //       }
  //     },
  //     (error: any) => {
  //       console.log(error);
  //     },
  //   );

  // }
  loadshopList(): void {
    this.orderService.getShopList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.shopList = response.data;
  
          // Sort shopList by shop name in ascending order
          this.shopList.sort((a: any, b: any) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
  
          // Assign sorted values to filter[0].values
          this.filter[0].values = this.shopList.map((shop: any) => ({
            title: shop.name,
            value: shop.shopId
          }));
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  
  /**
   * loads status list
   */

  loadstatusList(): void {

    this.orderService.getStatusList().subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // this.statusList =  response.data.status_list;
          // this.filter[6].values = (this.statusList.map((status: any) => {
          //   return { title: status.display_text, value: status.code };
          // }));
        }
      },
      (error: any) => {
        console.log(error);
      },
    );

  }

  /**
  * loads and set the segment data
  */
  loadFavoriteFilters(): void {
    let user = this.authService.getUser();
    if (user !== undefined) {
      let requestData: RequestData = {
        search: {
          filters: {
            simpleSearch: [], advSearch: [
              { column: "module", operator: "=", value: "order" },
              { column: "is_favorite", operator: "=", value: 1 },
              { column: "user_id", operator: "=", value: user.id }
            ], scope: { limit: 0, offset: 0 }
          }, sort: []
        }
      };
      let response = this.filterService.getFilter(requestData).subscribe(response => {
        if (response.status == 'SUCCESS') {
          this.favoriteFilters = response.data.filters;
          this.orderFilters.push(...this.favoriteFilters);
          console.log(this.favoriteFilters);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }
  }

  onFilterSelected(filter: any): void {
    //alert('hii')
    // console.log(filter);
    this.searchFilter?.loadFilterOnDemand(filter);
    // this.onOptionBarFilterData();
  }

  /**
  * option bar filter selection has been reset.
  */
  optionBarFilterReset(param?: any): void {
    //this.clearAllSelection();
    if (param?.filter) {
      this.searchFilter?.onFilterReset();
      this.searchFilter?.onFilterApply();
    } else {
      this.loadData();
    }
    // let reqData = this.buildRequestData();

  }




  /**
  * Create sort order
  * @returns sort criteria
  */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }
  /**
 *  Set the sort param and relead the data
 * @param $event sort data
 * {"active": "email","direction": "asc"}
 *   
 */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }
  /**
 * Builds the request data to be send to API
 * @returns Request Data
 */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      },
      export: {
        itemsOnly: false
      }
    };

    return requestData;
  }

  loadOrderSummery(requestData: any): void {
    this.orderService.getOrderSummery(requestData).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.orderSummary = response.data;
  
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  /**
   * loads the order data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;

    let requestData = this.buildRequestData();
    this.loadOrderSummery(requestData);
    let response = this.orderService.getOrdersList(requestData).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.setDataSource(response.data.orders);
        this.pagingData.length = response.data.total_count;
        this.statusList = response.data.status_list;
        this.filter[6].values = (this.statusList.map((status: any) => {
          return { title: status.display_text, value: status.code };
        }));
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        } else {
          this.stateManagerService.setState(this.constructor.name, requestData);
          this.onRowSelected(this.orderList[0]);
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }
  /**
  * 
  * @param event 
  * When page size is changed update paging data
  */
  onePageEvent(event: any): void {
    //alert(event.pageIndex);
    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;
    this.loadData();

  }
  /**
  * Set the column filter and reaload the data
  * @param columFilter 
  */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }
  /**
 * On order selected
 * @param orderInfo 
 */
  onRowSelected(orderInfo: OrderModel): void {
    this.selectedItem = orderInfo;
  }
  /**
    * Method is use to show order detail view.
    
    */

  actionOrderPageNav(data: any): void {
    const dialogRef = this.dialog.open(OrderViewComponent, {
      width: '90%', // Adjust the width as needed
      height: '90%',
      data: { id: data.orderId },
    });

  }

  /**
      * Method is use to eport file.
      */
  directToExportOrder(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = "400px";
    dialogConfig.height = "255px";
    dialogConfig.data = {
      // params: this.bodyParams,
      // totalCount: this.totalCount
    };
    const dialogRef = this.dialog.open(
      OrderExportComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined) {
        this.exportData(result);
      }
    });
  }

  /*
  export data based on condition selected 
  from the radio button
    */
  exportData(selectedValue: any): void {
    this.IsDownloading = true;
    const params = this.buildRequestData();

    if (selectedValue === 'all') {
      params.export.itemsOnly = false; //order summary

    } else {
      params.export.itemsOnly = true;

    }


    this.orderService.exportOrderHistory(params)
      .subscribe(
        blob => {

          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const currTime = formatDate(new Date(), 'yyyyMMddhh:mm:ss', 'en-US', '+0530');
          if (selectedValue === 'all') {
            link.download = 'online_order_summary_' + currTime + '.xlsx'; // Adjust filename if needed
          } else {
            link.download = 'online_order_itemlist_' + currTime + '.csv'; // Adjust filename if needed
          }
          link.click();
          window.URL.revokeObjectURL(url);
          this.IsDownloading = false;
        },
        error => {
          // Handle errors
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);          
          this.IsDownloading = false;
          // Display user-friendly error message to the user
        }
      );
  }

  /**
    * Method is use to download file.
    * @param data - Array Buffer data
    * @param type - type of the document.
    */
  downLoadFile(data: any, type: string): any {

    const headers = data.headers;
    const contentDisposition = headers.get('content-disposition');
    const blob = new Blob([data.body], { type: type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = contentDisposition;
    anchor.href = url;
    anchor.click();
    this.isLoading = false;

  }
  formatDate(date: Date): string {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  }



}
