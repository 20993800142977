import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MastersalesReportService } from './mastersales-report.service';
import { DateAdapter } from '@angular/material/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import moment from 'moment';
import { PermissionService } from 'src/app/auth/permission.service';




@Component({
    selector: 'app-mastersales-report',
    templateUrl: './mastersales-report.component.html',
    styleUrl: './mastersales-report.component.scss',
    standalone: false
})
export class MastersalesReportComponent {
  title: string = "Master Sales Report";
  IsDownloading = false;
  isLoading = false;
  shops:any = [];
  data:any;
  shopsIsOpen = false;
  regions:any= [];
  regionsIsOpen = false;
  departments:any = [];
  departmentsIsOpen = false;
  itemMainCategories:any = []
  itemMainCategoriesIsOpen = false;
  itemCategories:any = []
  itemCategoriesIsOpen = false;
  includeShopDetails = false;
  includeAddColumns = false;
  items:any = [];
  itemsIsOpen = false;
  sortByList = [
    { title: 'Sales Amount', value: 'total' },
    { title: 'Item Name', value: 'sale_item_name' },
    { title: 'Qty', value: 'qty' },
    { title: 'Gross Profit', value: 'gp' },
  ];
  dateType:any;
  dateTypeList = [
    { title: 'Order Date', value: 'order' },
    { title: 'Delivery Date', value: 'delivery' }
  ];
  sortBy: any;
  orderByList = [
    { title: 'Ascending', value: 'asc' },
    { title: 'Descending', value: 'desc' }
  ];
  orderBy: any;
  fgPeriod: FormGroup;
  fgSortOption: FormGroup;
  periodFrom: any;
  periodTo: any;
  searchParam = { search: { filters: { filter: [], scope: { offset: 0, limit: 1000000 } }, sort: [{ column: "name", order: "ASC" }] } }
  reportRequest: any;
  permission: any;

  departmentIsOpen = false;
  constructor(private router: Router,private mastersalesReportService: MastersalesReportService,
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private permissionService: PermissionService,) {
      this.permission = permissionService.getPagePermission('mastersalesreport');

    this.dateAdapter.setLocale('en-NZ');
    this.loadDepartmentList();
    this.loadShopList();
    this.loadRegionList();
    this.loadItemCategoryList();
    this.loadItemMainCategoryList();
    this.loadItemList();

    this.periodFrom = new Date();
    this.periodFrom.setHours(0,0,0,0);;
    this.periodFrom.setDate(this.periodFrom.getDate() - (this.periodFrom.getDay() + 6) % 7);
    this.periodTo = new Date();
    this.periodTo.setHours(0,0,0,0);;

    this.sortBy = this.sortByList[0].value;
    this.orderBy = this.orderByList[0].value;
    this.dateType = this.dateTypeList[0].value;
    this.fgPeriod = this.fb.group({
      periodFrom: [""],
      periodTo: [""]
    });

    this.fgSortOption = this.fb.group({
      sortBy: [""],
      orderBy: [""]
    });

  }
  ngOnInit(): void {
    this.isLoading = false; // Set to true on page load
  }

  loadDepartmentList() {
    return this.mastersalesReportService.getDepartmentListExt(this.searchParam).subscribe(
      (response: any) => {
        this.departments = [];
        var index = 0;
        response.data.forEach((dept:any) => {
          var data = { index: index, item: dept, selected: false };
          this.departments.push(data);
          index++;
        });

      },
      (error: any) => {

      },
    );
  }

  loadShopList() {
    return this.mastersalesReportService.getShopListExt(this.searchParam).subscribe(
      (response: any) => {
        this.shops = [];
        var index = 0;
        response.data.forEach((item:any) => {
        
         var data = { index: index, item: item, selected: false };
         this.shops.push(data);
          index++;
        });

      },
      (error: any) => {

      },
    );
  }

  loadRegionList() {
    return this.mastersalesReportService.getRegionListExt(this.searchParam).subscribe(
      (response: any) => {
        this.regions = [];
        var index = 0;
        response.data.forEach((item:any) => {
          var data = { index: index, item: item, selected: false };
         this.regions.push(data);
          index++;
        });

      },
      (error: any) => {

      },
    );
  }

  loadItemList() {
    return this.mastersalesReportService.getItemsList(this.searchParam).subscribe(
      (response: any) => {
        this.items = [];
        var index = 0;
        response.data.forEach((item:any) => {
          var data = { index: index, item: item, selected: false };
          this.items.push(data);
          index++;
        });

      },
      (error: any) => {

      },
    );
  }

  loadItemMainCategoryList() {
    var searchParam = { search: { filters: { filter: [{ "column": "ifnull(parent_id,0)", "operator": "=", "value": "0" }], scope: { offset: 0, limit: 1000000 } }, sort: [{ column: "name", order: "ASC" }] } }
    return this.mastersalesReportService.getItemCategoryListExt(searchParam).subscribe(
      (response: any) => {
        this.itemMainCategories = [];
        var index = 0;
        response.data.forEach((item:any) => {
          var data = { index: index, item: item, selected: false };
          this.itemMainCategories.push(data);
          index++;
        });

      },
      (error: any) => {

      },
    );
  }

  loadItemCategoryList() {
    var searchParam = { search: { filters: { filter: [{ "column": "ifnull(parent_id,0)", "operator": "<>", "value": "0" }], scope: { offset: 0, limit: 1000000 } }, sort: [{ column: "name", order: "ASC" }] } }
    return this.mastersalesReportService.getItemCategoryListExt(searchParam).subscribe(
      (response: any) => {
        this.itemCategories = [];
        var index = 0;
        response.data.forEach((item:any) => {
          var data = { index: index, item: item, selected: false };
          this.itemCategories.push(data);
          index++;
        });

      },
      (error: any) => {

      },
    );
  }
  getSelectedCount(sourceList: any[]): any {
    var count = 0;
    sourceList.forEach(el => {
      if (el.selected) {
        count++;
      }
    })
    return sourceList.length + '/' + ((count > 0) ? (count) : 'ALL');
  }

  reset(sourceList: any[]): any {
    sourceList.forEach(el => {
      el.selected = false;
    });
  }
  export(): void {
    var dateFrom = moment(this.periodFrom);
    var dateTo = moment(this.periodTo);
   

    if (!dateFrom.isValid()) {
      let snackBarData: SnackBarData = {
        message: "Please enter a valid date. Invalid date from!",
        title: 'Failed!!!',
        type: 'error'
      }
      this.snackBarService.openSnackBar(snackBarData);
      return;
    } else if (!dateTo.isValid()) {
      
      let snackBarData: SnackBarData = {
        message: "Please enter a valid date. Invalid date to!",
        title: 'Failed!!!',
        type: 'error'
      }
      this.snackBarService.openSnackBar(snackBarData);
      return;
    } else if (dateTo < dateFrom) {
      let snackBarData: SnackBarData = {
        message: "Date from can not be greater than date to. Invalid date from!",
        title: 'Failed!!!',
        type: 'error'
      }
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    this.reportRequest = {
      date_type:this.dateType,
      date_from: moment(this.periodFrom).format("DD-MM-YYYY"),
      date_to: moment(this.periodTo).format("DD-MM-YYYY"),
      service_type_to: [],
      hd_service_type_to_criteria: "",
      hd_shop_to_criteria: this.getSelectedHdrs(this.shops),
      shop_to: this.getSelectedShopIDs(),
      hd_region_to_criteria: this.getSelectedHdrs(this.regions),
      region_to: this.getSelectedIDs(this.regions),
      hd_department_to_criteria: this.getSelectedHdrs(this.departments),
      department_to: this.getSelectedIDs(this.departments),
      main_group_to: this.getSelectedIDs(this.itemMainCategories),
      group_to: this.getSelectedIDs(this.itemCategories),
      item_to: this.getSelectedIDs(this.items),
      sort_by: this.sortBy,
      order_by: this.orderBy,
      is_shop_details_include: (this.includeShopDetails) ? 1 : 0,
      show_dep_group: (this.includeAddColumns) ? 1 : 0,
      shop_name: "",
      shop_id: "",
      submit: "Submit"
    };
    this.isLoading = true;
    this.IsDownloading = true;

    this.mastersalesReportService.generateMasterSalesReport(this.reportRequest).subscribe(
      (response: any) => {
        if (response.body.type == "application/vnd.ms-excel") {
          this.downLoadFile(response);
        } else {
          
          let snackBarData: SnackBarData = {
            message: 'No records found.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.isLoading = false;
        this.IsDownloading = false;
      },
      (error: any) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to generate report.'+ error,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
        this.isLoading = false;
        this.IsDownloading = false;
      },
    );

  }

  /**
       * Method is use to download file.
       * @param response - Array Buffer data
       */
  downLoadFile(response: any): any {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response.body);
    let now = new Date();
    const cdate= moment(now).format('yyyymmddHHMMss');
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    downloadLink.setAttribute('download', "master_sales_report-" + cdate + ".xls");
    document.body.appendChild(downloadLink);
    downloadLink.click();

  }

  getSelectedShopIDs(): any[] {
    var ids:any = [];
    this.shops.forEach((element:any) => {
      if (element.selected) {
        ids.push(element.item.shopId)
      }
    });

    return ids;
  }

  getSelectedHdrs(list: any[]): any {
    var hdrs = "";
    list.forEach(element => {
      if (element.selected) {
        hdrs += element.item.name + "::";
      }
    });

    return hdrs;
  }

  getSelectedIDs(list: any[]): any[] {
    var ids:any = [];
    list.forEach(element => {
      if (element.selected) {
        ids.push(element.item.id)
      }
    });

    return ids;
  }

}
