import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionModel } from '../models/region.model';
import { RegionService } from '../region.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { PermissionService } from 'src/app/auth/permission.service';

@Component({
  selector: 'app-region-edit',
  templateUrl: './region-edit.component.html',
  styleUrl: './region-edit.component.scss',
  animations: [
    trigger('if', [
      // ... animation states and styles
    ])
  ]
})
export class RegionEditComponent implements DirtyCheck {
  title: string = "New Region";
  editForm: FormGroup;
  regionInfo: RegionModel = { isValid: 1, isDeleted: 0, isSystem: 0 };
  isFormDirty: boolean = false;
  permission: any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private regionService: RegionService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
    private permissionService: PermissionService,) {
      this.permission = permissionService.getPagePermission('region');

    let regionId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.regionInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.regionInfo.name, [Validators.required]),
      description: this.builder.control(this.regionInfo.description),
      displayOrder: this.builder.control(this.regionInfo.displayOrder, [Validators.pattern('^[0-9]*$')]),
      isValid: this.builder.control(this.regionInfo.isValid === 1),
    });


    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (regionId !== null && parseInt(regionId) !== 0) {
      this.setregionInfo(regionId);
    }
  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/region']);

  }

  /**
   * If tas is is null set an empty region info
   * if region id is not null call api to rtrive the info
   * @param regionId 
   */
  setregionInfo(regionId: any): void {

    let response = this.regionService.getId(regionId).subscribe({
      next: (response) => {
        if (response.successCode === 0) {
          this.regionInfo = response.data;
          this.editForm.patchValue({
            ...this.regionInfo,
            isValid: this.regionInfo.isValid === 1,
          });
          this.editForm.patchValue(this.regionInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.regionInfo.id == 0) ? "New Region" : "Editing : " + this.regionInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
 
  save(): void {
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
    }else{ 
      Object.assign(this.regionInfo, this.editForm.value);
      

      // Assuming 1 for createdBy and updatedBy, 0 for isDeleted and isSystem
      const formValues = this.editForm.value;
      this.regionInfo = {
        ...this.regionInfo,
        ...formValues,
        isValid: formValues.isValid ? 1 : 0,
      };
 
      const activeUser: any = this.auth.getUser();

      if (this.regionInfo.id === 0 || this.regionInfo.id === undefined) {
        this.regionInfo.createdBy = activeUser.id;
      } else {
        this.regionInfo.updatedBy = activeUser.id;
      }

      this.regionService.update(this.regionInfo).subscribe({
        next: (response: any) => {
          if (response.successCode === 0) {
            this.isFormDirty = false;
            var data = response.data;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + (this.regionInfo ? this.regionInfo.name : '') + ' has been updated successfully.',
            };
            this.snackBarService.openSnackBar(snckBarData);
            
              this.router.navigate(['region']);
            
           
          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
   });
}
}


  getErrorDescription(controlName: string): string | null {
    var message = null;
    const errors = this.editForm.get(controlName)?.errors;
    if (errors) {
      if (errors['required'])
        message = "This field should be entered.";
      else if (errors['maxlength'])
        message = "Max length of the code is " + errors['maxlength'].requiredLength;
    }
    return message;
  }  
}

