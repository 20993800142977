import { Component } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { ItemsModel } from '../models/items.model';
import { ItemsService } from '../items.service';
import { AuthService } from 'src/app/auth/auth.service';
export interface PeriodicElement {
  id: number;
  itemName: string;
  categoryName: string;
  price: number;
}
@Component({
  selector: 'app-import-item',
  templateUrl: './import-item.component.html',
  styleUrl: './import-item.component.scss'
})
export class ImportItemComponent {

  itemsList: ItemsModel[];
  showSearch: boolean = false;
  hideAdvancedFilter: boolean = false;
  isLoading = true;
  showAll = true;
  selectedItemFilter: any;

  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "sale_item.code",
    },
    {
      title: "Name",
      column: "sale_item.name",
    },

  ];

  displayedColumns = ['moreVert', 'item', 'select', 'code', 'name', 'category', 'price'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: ItemsModel;
  categoryList: any;
  public CATEGORY_SELECT_ID: any = 0;
  categoryListFiltered: any;
  public selection: any = new SelectionModel<PeriodicElement>(true, []);
  selectedItemIds: Set<number> = new Set<number>();
  showOnlySelected = false;
  displayedItems: ItemsModel[] = [];
  selectedItems: any[] = [];
  showSelectedOnly: boolean = false;
  selectedIds: number[] = []; // Array to keep track of selected item IDs
  showSelectedItems: boolean = false;
  isSaveAttempted = false;



  constructor(private breakpointObserver: BreakpointObserver,
    private itemsService: ItemsService,
    public dialog: MatDialog,
    public auth: AuthService,
    private snackBarService: SnackBarService,
    private router: Router) {

    this.itemsList = [];
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
      this.hideAdvancedFilter = true;

    });


    this.loadData();
    this.loadProvider();


  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }

  toggleDisplaySelected(): void {
    this.showSelectedItems = !this.showSelectedItems;
    if (this.showSelectedItems) {
      this.pagingData.pageIndex = 0;
      // this.selectedItemFilter = {
      //   column: 'saleItemId',
      //   operator: 'in',
      //   value: Array.from(this.selectedItemIds)
      // };
      this.selectedItemFilter = [];
      this.selectedItemIds.forEach((id: any) => {
        const filter = {
          column: 'id',
          operator: '=',
          value: id
        };
        this.selectedItemFilter?.push({
          column: 'id',
          operator: '=',
          value: id
        });
      });

    } else {
      this.selectedItemFilter = undefined;
    }
    this.loadData();
  }
  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  buildJWSRequsetData(requestData: RequestData): any {
    var jwsRequestData: {
      search: {
        filters: {
          filter: {
            column: string;
            operator: string;
            value: any;
          }[], scope: { offset: number, limit: number }
        }, sort: []
      }
    } = {
      search: {
        filters: {
          filter:
            [], scope: { offset: 0, limit: 10 }
        }, sort: []
      }
    }
    var offset = requestData.search?.filters?.scope?.offset;
    var limit = requestData.search?.filters?.scope?.limit;
    jwsRequestData.search.filters.scope.offset = (offset != undefined) ? offset : 0;
    jwsRequestData.search.filters.scope.limit = (limit != undefined) ? limit : 0;

    if (requestData.search?.filters?.simpleSearch != undefined && requestData.search?.filters?.simpleSearch.length > 0) {
      var searchValue = requestData.search?.filters?.simpleSearch[0].value;
      var nameFilter = { column: "name", operator: "like", value: searchValue };
      var codeFilter = { column: "code", operator: "like", value: searchValue };
      jwsRequestData.search.filters.filter.push(nameFilter);
      jwsRequestData.search.filters.filter.push(codeFilter);


    }
    if (this.selectedItemFilter) {
      jwsRequestData.search.filters.filter.push(...this.selectedItemFilter);
    }
    return jwsRequestData;
  }
  /**
   * Returns to items list
   */
  navBack(): void {

    this.router.navigate(['/item']);

  }

  directToImportItem(): any {
    this.router.navigate(['import-item', 0]);
  }
  /**
 * loads the category items data based on the conditions
 */
  loadProvider(): void {
    this.itemsService.getItemCategoryList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.categoryList = response.data;
          this.categoryList.splice(0, 0, { 'id': this.CATEGORY_SELECT_ID, 'code': 'SELECT', 'name': 'SELECT' });
          this.categoryListFiltered = this.categoryList.filter((cl: any) => cl.parentId !== 0 && cl.parentId !== null);
        }
      },
      (error: any) => { }
    );
    this.loadData();
  }

  /**
   * loads the items data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    var jwsRequestData = this.buildJWSRequsetData(requestData);
    // If we're showing selected items, add the filter to the request
    if (this.showSelectedItems && this.selectedItemFilter) {
      jwsRequestData.search.filters.filter.push(...this.selectedItemFilter);
    }
    let response = this.itemsService.getSaleItemImportList(jwsRequestData).subscribe(response => {
      if (response.successCode === 0) {
        this.itemsList = response.data.map((item: any) => ({
          ...item,
          selected: this.selectedItemIds.has(item.saleItemId)
        }));
        this.pagingData.length = response.recordsCount;
        this.updateDisplayedItems();

        //this.selection.clear();
        this.pagingData.length = response.recordsCount;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
      // this.loadData();

    });

  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch


  }

  clearAllSelection(): void {
    // Clear the selection model
     this.selection.clear();
     // Clear the set that tracks selected item IDs
     this.selectedItemIds.clear();
   // Update the displayed items to reflect the cleared selection
      this.toggleDisplaySelected();
    //this.updateDisplayedItems();
    
  }
  

  updateDisplayedItems(): void {
    if (this.showSelectedOnly) {
      this.displayedItems = this.itemsList.filter(item => item.selected);
    } else {
      this.displayedItems = this.itemsList;
    }
    // Update the selection model to match the current page
    this.selection.clear();
    this.displayedItems.forEach(item => {
      if (item.selected) {
        this.selection.select(item);
      }
    });
  }

  onRowSelected(item: ItemsModel): void {
    this.selection.toggle(item);
    item.selected = this.selection.isSelected(item);
    if (item.selected) {
      this.selectedItemIds.add(item.saleItemId);
    } else {
      this.selectedItemIds.delete(item.saleItemId);
    }
    this.updateDisplayedItems();
  }


  toggleAllSelection(event: any): void {
    if (event.checked) {
      this.itemsList.forEach(item => {
        if (!this.selectedItemIds.has(item.saleItemId)) {
          this.selectedItemIds.add(item.saleItemId);
        }
      });
    } else {
      this.itemsList.forEach(item => {
        if (this.selectedItemIds.has(item.saleItemId)) {
          this.selectedItemIds.delete(item.saleItemId);
        }
      });
    }
    this.updateDisplayedItems();
  }


  isAllSelected(): boolean {
    const numSelected = this.itemsList.filter(item => this.selectedItemIds.has(item.saleItemId)).length;
    const numRows = this.itemsList.length;
    return numSelected === numRows;
  }


  showSelectedItem(): void {
    this.showSelectedOnly = true;
    this.updateDisplayedItems();
  }

  showAllItems(): void {
    this.showSelectedOnly = false;
    this.updateDisplayedItems();
  }

  onePageEvent(event: any): void {
    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;
    this.loadData();
  }

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();
  }


  /**







  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData(sd: any): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On items selected
 * @param itemsInfo 
 */




  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }
  
  saveImportedItem(): any {
   
    
    // Filter the selected items from the list and map them to the correct format
    const selectedItems = this.itemsList
      .filter(item => this.selectedItemIds.has(item.saleItemId))
      .map(item => this.getItemToImport(item));
  
    // Check if there are any items with a selected category as 'SELECT'
    if (selectedItems.findIndex(e => e.ooItemCategoryId === this.CATEGORY_SELECT_ID) > -1) {
      let snackBarData: SnackBarData = {
        message: 'Select a category',
        title: 'Error',
        type: 'error'
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
   
  
    this.saveItem(selectedItems);
  }
  
  getItemToImport(item: any): any {
    const itemToImport = {
      'name': item.name,
      'code': item.code,
      'ooItemCategoryId': item.ooItemCategoryId,
      'fixedPrice': item.fixedPrice,
      'saleItemId': item.saleItemId,
      'isCustomizable': item.itemType === 2 ? 1 : 0,
      'isValid': 0,
      'createdBy': 1, // Assuming '1' is the correct user ID; adjust as needed
      'updatedBy': 1,
      'displayOrder': 1, // Adjust this value if needed
      'isDeleted': 0,
      'isSystem': 0,
      'nutritionalInfo': '',
      'preparationTime': 0
    };
        return itemToImport;

  }
  

  // saveImportedItem(): any {
  //   const selectedItems = this.itemsList.filter(item => this.selectedItemIds.has(item.saleItemId));
  //   for (let i = 0; i < this.selection.selected.length; i++) {
  //     const itemToImport = this.getItemToImport(i);
  //     if (itemToImport.ooItemCategoryId === this.CATEGORY_SELECT_ID) {
  //       this.selection.selected[i].hasError = true;
  //     }
  //     selectedItems.push(itemToImport);
  //   }

  //   if (selectedItems.findIndex(e => e.ooItemCategoryId === this.CATEGORY_SELECT_ID) > -1) {
  //     let snackBarData: SnackBarData = {
  //       message: 'Select a category',
  //       title: 'Error',
  //       type: 'error'
  //     };
  //     this.snackBarService.openSnackBar(snackBarData);
  //     return;
  //   }

  //   this.saveItem(selectedItems);
  // }

  // getItemToImport(i: any): any {
  //   const activeUser: any = this.auth.getUser();
  //   const itemToImport = {
  //     'name': this.selection.selected[i].name,
  //     'code': this.selection.selected[i].code,
  //     'ooItemCategoryId': this.selection.selected[i].ooItemCategoryId,
  //     'fixedPrice': this.selection.selected[i].fixedPrice,
  //     'saleItemId': this.selection.selected[i].saleItemId,
  //     'isCustomizable': (this.selection.selected[i].itemType === 2) ? 1 : 0,
  //     'isValid': 0,
  //     'createdBy': 1,
  //     'updatedBy':1,
  //     'displayOrder': 1,
  //     'isDeleted': 0,
  //     'isSystem': 0,
  //     'nutritionalInfo': '',
  //     'preparationTime': 0
  //   };
  //   return itemToImport;
  // }

  saveItem(selectedItems: any): any {
    if (selectedItems.length !== 0) {
      this.itemsService.saveImportedItemDetails(selectedItems).subscribe(
        (response: any) => {
          if (response.successCode === 0) {
            let snackBarData: SnackBarData = {
              message: 'Item saved',
              title: 'Success',
              type: 'success'
            };
            this.snackBarService.openSnackBar(snackBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Error',
              type: 'error'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        (error: any) => {
          console.log(error);
          let snackBarData: SnackBarData = {
            message: 'Something went wrong',
            title: 'Error',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      );
    } else {
      let snackBarData: SnackBarData = {
        message: 'Please select items',
        title: 'Error',
        type: 'error'
      };
      this.snackBarService.openSnackBar(snackBarData);
    }
  }
  getNotificationBarHeight(): string {
    return this.itemsList.some(item => item.selected) ? '60px' : '0px';
  }
  getSelectionNotificationMsg(): string {
    if (this.selection.selected.length == this.pagingData.length) {
      return "All items are selected."
    } else {
      return "Out of " + this.pagingData.length + " items, " + this.selection.selected.length + (this.selection.selected.length == 1 ? " is" : " are") + " selected.";// Click on the 'Select All' button to select all customers."
    }
  }

}