
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from '../../model/user.model';
import { UserService } from '../../user.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackbarService } from 'src/app/common/services/snackbar.service';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';

@Component({
    selector: 'app-user-edit-dialog',
    templateUrl: './user-edit-dialog.component.html',
    styleUrls: ['./user-edit-dialog.component.scss'],
    standalone: false
})
export class UserEditDialogComponent {

  title: string;

  editForm!: FormGroup;
  userInfo: UserModel;

  constructor(private builder: FormBuilder,
    private dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private item: any,
    private userService: UserService, private snackBarService: SnackBarService
  ) {
    this.userInfo = JSON.parse(JSON.stringify(item));
    this.editForm = this.builder.group({
      login_id: this.builder.control(this.userInfo.login_id, [Validators.required, Validators.maxLength(50)]),
      f_name: this.builder.control(this.userInfo.name, [Validators.required, Validators.maxLength(50)]),
      address: this.builder.control(this.userInfo.address, [Validators.required, Validators.maxLength(250)]),
      email: this.builder.control(this.userInfo.email, [Validators.required, Validators.email]),
      phone: this.builder.control(this.userInfo.phone, [Validators.required, Validators.minLength(10)]),
      is_valid: this.builder.control(this.userInfo.is_valid, [])
    });
    this.title = this.getTitle();
  }

  /**
   * On save
   * if the details are valid call the api to update the changes.
   */
  save(): void {

    if (this.editForm.valid) {
      // this.userInfo = this.editForm.value;
      Object.assign(this.userInfo, this.editForm.value);
      this.userService.update(this.userInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.dialogRef.close(this.userInfo);
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.userInfo.name + "  has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData:SnackBarData={
              message:response.message,
              title:'Failed!!!',
              type:'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData:SnackBarData={
            message:'Failed to update. Connection to the server failed',
            title:'Failed!!!',
            type:'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }

  /**
   * Close the edit dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.item.id == undefined) ? "New" : "Editing : " + this.item.f_name + " " + this.item.l_name;
    return title;
  }

}
