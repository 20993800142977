export const environment = {
  production: false,
  buildNo:202410282056,
  ver:'DEV 2.0.1',
  baseUrl   : 'https://dev.admin.oov2.mojosoft.work/ws/',
  wsPhp: 'https://dev.admin.oov2.mojosoft.work/ws-ext/',
  apiReportUrl: 'https://dev.admin.oov2.mojosoft.work/rpt-ws/',
  uplodFilePath: 'https://dev.admin.oov2.mojosoft.work/resources/',
  itemImageWidth: 1000,
  itemImageHeight: 1000,
  userId: 'oos',
  password: '1234',
  appKey: "e568458e9121478125d076b344e26c9e",
  crm:{
    apiUrl:'https://dev.crm.mojosoft.work/ws',
    userId: 'oos',
    password: '1234',
    appKey: "e568458e9121478125d076b344e26c9e",
  }
};
