import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarData } from './model/snackbar.model';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})


export class SnackBarComponent {


  constructor(private snackbar :MatSnackBar, public sbRef: MatSnackBarRef<SnackBarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }

  closeSnackbar(): void{
    this.snackbar.dismiss();
  }

}
