import { Component } from '@angular/core';

@Component({
    selector: 'app-sorter',
    templateUrl: './sorter.component.html',
    styleUrls: ['./sorter.component.scss'],
    standalone: false
})
export class SorterComponent {
  isVisible:boolean=false;


}
