import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PickupLocationModel } from '../models/pickup-location.model';
import { PickupLocationService } from '../pickup-location.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { PermissionService } from 'src/app/auth/permission.service';


@Component({
    selector: 'app-pickup-location-edit',
    templateUrl: './pickup-location-edit.component.html',
    styleUrl: './pickup-location-edit.component.scss',
    animations: [
        trigger('if', [
        // ... animation states and styles
        ])
    ],
    standalone: false
})

export class PickupLocationEditComponent implements DirtyCheck {
  title: string = "New PickupLocation";
  editForm: FormGroup;
  pickupInfo: PickupLocationModel = { isValid: 1, isDeleted: 0};
  isFormDirty: boolean = false;
  permission: any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private pickupService: PickupLocationService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
    private permissionService: PermissionService,) {
      this.permission = permissionService.getPagePermission('pickup-location')

    let pickupId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      title: this.builder.control(this.pickupInfo.title, [Validators.required, Validators.maxLength(50)]),
      kitchenRemarks: this.builder.control(this.pickupInfo.kitchenRemarks, [Validators.required]),
      warnText: this.builder.control(this.pickupInfo.warnText),
      receiptRemarks: this.builder.control(this.pickupInfo.receiptRemarks),
      displayOrder: this.builder.control(this.pickupInfo.displayOrder, [Validators.pattern('^[0-9]*$')]),
      isValid: this.builder.control(this.pickupInfo.isValid === 1 )
    });


    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (pickupId !== null && parseInt(pickupId) !== 0) {
      this.setpickupInfo(pickupId);
    }
  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/pickup-location']);

  }

  /**
   * If tas is is null set an empty pickup-location info
   * if pickup-location id is not null call api to rtrive the info
   * @param pickupId 
   */
  setpickupInfo(pickupId: any): void {

    let response = this.pickupService.getId(pickupId).subscribe({
      next: (response) => {
        if (response.successCode === 0) {
          this.pickupInfo = response.data;
          this.editForm.patchValue({
            ...this.pickupInfo,
            isValid: this.pickupInfo.isValid === 1,
          });
          this.editForm.patchValue(this.pickupInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.pickupInfo.id == 0) ? "New Pickup Location" : "Editing : " + this.pickupInfo.title;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
  save(): void {
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
    }else{ 
      Object.assign(this.pickupInfo, this.editForm.value);

      // Assuming 1 for createdBy and updatedBy, 0 for isDeleted and isSystem
      const formValues = this.editForm.value;
      this.pickupInfo = {
        ...this.pickupInfo,
        ...formValues,
        isValid: formValues.isValid ? 1 : 0,
      };


      this.pickupService.update(this.pickupInfo).subscribe({
        next: (response: any) => {
          if (response.successCode === 0) {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + (this.pickupInfo ? this.pickupInfo.title : '') + ' has been updated successfully.',
            };
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['pickup-location']);
          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
      });
    }
  }


  getErrorDescription(controlName: string): string | null {
    var message = null;
    const errors = this.editForm.get(controlName)?.errors;
    if (errors) {
      if (errors['required'])
        message = "This field should be entered.";
      else if (errors['maxlength'])
        message = "Max length of the code is " + errors['maxlength'].requiredLength;
    }
    return message;
  }  
}

