import { Component, ViewEncapsulation,ViewChild } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { PickupLocationService } from './pickup-location.service';
import { PickupLocationModel } from './models/pickup-location.model';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam,SearchFilterComponent } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/auth/permission.service';
@Component({
    selector: 'app-pickup-location',
    templateUrl: './pickup-location.component.html',
    styleUrl: './pickup-location.component.scss',
    standalone: false
})
export class PickupLocationComponent {
  pickupList: PickupLocationModel[]=[];
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;
  filter: FilterListItemModel[] = [
    {
      title: "Title",
      column: "title",
    },
    {title: "Valid",
      column: "is_valid",
      type: "boolean",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
  ];

  displayedColumns = ['item','title','Valid','action'];
  pagingData = { length: 0, pageSize: 50, pageIndex: 0 };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: PickupLocationModel;
  dataSource = new MatTableDataSource(this.pickupList);
  permission: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private pickupService: PickupLocationService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router,private stateManagerService: StateManagerService,private permissionService:PermissionService) {

    this.pickupList = [];
    this.permission = permissionService.getPagePermission('pickup-location');
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });


    //this.loadData();
    
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  
  
  

  /**
   * loads the PickupLocation data based on the conditions
   */

    loadData(): void {
      this.isLoading = true;
      let requestData = this.buildRequestData();
      let response = this.pickupService.getPickupLocationListExt(requestData).subscribe(response => {
        if (response.status == 'SUCCESS') {
          this.setDataSource(response.data.location);
          this.pagingData.length = response.data.total_count;
          if (this.pagingData.length == 0) {
            let snackBarData: SnackBarData = {
              message: 'No data found. Please remove or change the filter if any.',
              title: 'No Data!!!',
              type: 'warn'
            }
            this.snackBarService.openSnackBar(snackBarData);
          } else {
            this.stateManagerService.setState(this.constructor.name, requestData);
            // if(this.columnSortData!=undefined && this.columnSortData.length>0){
            //   var sort=this.columnSortData[0];
            //    this.setSortColumn(sort.column,sort.order);
            // }
          }
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.isLoading = false;
      });
  
    }

  // loadData(): void {
  //   this.isLoading=true;
  //   let requestData = this.buildRequestData();
  //   let response = this.pickupService.getPickupLocationListExt(requestData).subscribe(response => {
  //     if (response.status == 'SUCCESS') {
  //       this.pickupList = response.data.location;
  //       this.pagingData.length = response.data.total_count;
  //       if( this.pagingData.length ==0){
  //         let snackBarData:SnackBarData={
  //           message:'No data found. Please remove or change the filter if any.',
  //           title:'No Data!!!',
  //           type:'warn'
  //         }
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     } else {
  //       let snackBarData:SnackBarData={
  //         message:response.message,
  //         title:'Failed!!!',
  //         type:'error'
  //       }
  //       this.snackBarService.openSnackBar(snackBarData);
  //     }
  //     this.isLoading=false;
  //   });

  // }
   /** 
  * Set the datasource
  */
   setDataSource(pickupList:PickupLocationModel[]){
    this.dataSource=new MatTableDataSource(pickupList);
    this.dataSource.sort=this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    // this.loadData();
  }
      /**
 * loads the previous state if exists
 */
loadPreviousState(): void {

  var previousState = this.stateManagerService.getState(this.constructor.name);

  if (previousState !== undefined && this.searchFilter != undefined) {

    if (previousState.state.search.filters.scope) {
      this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
      this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
    }

    if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
      this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
    }

    this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
    this.searchFilter.setSearchFilter(this.searchColumFilter,true);

  } else {
    this.setSortColumn('title','asc');
    this.loadData();
  }

}

/**
 * Sets the sort column
 * @param column 
 * @param direction 
 */
setSortColumn(column: string, direction: SortDirection): void {
  this.columnSortData.length = 0;
  this.columnSortData.push({ column: column, order: direction});
  this.sort.sort({
    id: column,
    start: direction,
    disableClear: false,
  });

  // this.sort.active=column;
  // this.sort.direction=direction;
  

}


  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new region
   */
  addClicked(): void {

    this.router.navigate(['pickup-location-edit', 0]);
  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }


 /**
 * On edit the item
 * Show the edit dialog
 */
 onEdit(pickupInfo: PickupLocationModel): void {
  if (pickupInfo && pickupInfo.id !== undefined) {
    this.router.navigate(['pickup-location-edit', pickupInfo.id]);
  } else {
    // Handle the case where the id is undefined or not present
    console.error('Invalid pickupInfo object for editing:', pickupInfo);
    // Optionally, display a message to the user or log the issue
  }
}



  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
   * On delete menu item selected
   * Confirm the region action and call the api to update
   */
  onDelete(pickupInfo: PickupLocationModel): void {
    const regionId = pickupInfo.id;
    const message = `Are you sure, you want to delete ` + (pickupInfo.title+ "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.pickupService.delete(pickupInfo).subscribe({
          next: (response) => {
            if (response.successCode === 0) {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + pickupInfo.title + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On region selected
 * @param pickupInfo 
 */
  onRowSelected(pickupInfo: PickupLocationModel): void {
    this.selectedItem = pickupInfo;
  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }
  exportClicked() {
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
  
    this.pickupService.downloadExcel(requestData)
      .subscribe(
        blob => {
          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'pickuplocation.xlsx'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
          this.IsDownloading = false;
        },
        error => {
          // Handle errors
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);          this.isLoading = false;
          this.IsDownloading = false;
          // Display user-friendly error message to the user
        }
      );
  }

}
