
import { Component } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { ShopDetails } from '../models/shopDetails.model';
import { ShopsService } from '../shops.service';
import { AuthService } from 'src/app/auth/auth.service';
export interface PeriodicElement {
  shopId: number;
  code: string;
  shopName: string;
  regionName: number;
}
@Component({
    selector: 'app-import-shop',
    templateUrl: './import-shop.component.html',
    styleUrl: './import-shop.component.scss',
    standalone: false
})
export class ImportShopComponent {
  shopsList!: ShopDetails[];
  showSearch: boolean = false;
  hideAdvancedFilter: boolean = false;

  isLoading = true;
  showAll = true;

  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "code",
    },
    {
      title: "ShopName",
      column: "name",
    },

  ];

  displayedColumns = ['moreVert', 'shop', 'select', 'code', 'name', 'region'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedshop?: ShopDetails;
  public REGION_SELECT_ID: any = 0;
  public selection: any = new SelectionModel<PeriodicElement>(true, []);
  showOnlySelected = false;
  displayedShops!: any[];
  selectedShops!: any[];
  showSelectedOnly!: boolean;
  regionList: any;
  isSaveAttempted = false;


  constructor(private breakpointObserver: BreakpointObserver,
    private shopsService: ShopsService,
    public dialog: MatDialog,
    public auth: AuthService,
    private snackBarService: SnackBarService,
    private router: Router) {

    this.regionList = [];
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
      this.hideAdvancedFilter = true;

    });


    this.loadData();
    this.loadProvider();
    this.updateDisplayedshops();


  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  buildJWSRequsetData(requestData: RequestData): any {
    var jwsRequestData: {
      search: {
        filters: {
          filter: {
            column: string;
            operator: string;
            value: any;
          }[], scope: { offset: number, limit: number }
        }, sort: []
      }
    } = {
      search: {
        filters: {
          filter:
            [], scope: { offset: 0, limit: 10 }
        }, sort: []
      }
    }
    var offset = requestData.search?.filters?.scope?.offset;
    var limit = requestData.search?.filters?.scope?.limit;
    jwsRequestData.search.filters.scope.offset = (offset != undefined) ? offset : 0;
    jwsRequestData.search.filters.scope.limit = (limit != undefined) ? limit : 0;
    if (requestData.search?.filters?.simpleSearch != undefined && requestData.search?.filters?.simpleSearch.length > 0) {
      var searchValue = requestData.search?.filters?.simpleSearch[0].value;
      var nameFilter = { column: "name", operator: "like", value: searchValue };
      var codeFilter = { column: "code", operator: "like", value: searchValue };
      jwsRequestData.search.filters.filter.push(nameFilter);
      jwsRequestData.search.filters.filter.push(codeFilter);


    }

    return jwsRequestData;
  }
  /**
   * Returns to shops list
   */
  navBack(): void {

    this.router.navigate(['/shop']);

  }

  directToImportshop(): any {
    this.router.navigate(['import-shop', 0]);
  }
  /**
 * loads the category shops data based on the conditions
 */

  loadProvider(): void {
    this.shopsService.getRegionList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          const region = response.data;
          this.regionList = region.filter((e: any) => e.isValid !== 0);
          this.regionList.splice(0, 0, { 'id': this.REGION_SELECT_ID, 'code': 'SELECT', 'name': 'SELECT' });
        }
      },
      (error: any) => { }
    );
    this.loadData();
  }

  /**
   * loads the shops data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    var jwsRequestData = this.buildJWSRequsetData(requestData);
    let response = this.shopsService.getShopImportList(jwsRequestData).subscribe((response: any) => {
      if (response.successCode === 0) {
        this.shopsList = response.data;
        this.selection.clear();
        this.pagingData.length = response.recordsCount;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
    this.loadData();
  }
  toggleAllSelection(event: any): void {
    this.regionList.forEach((shop: { selected: any; }) => shop.selected = event.checked);
    this.updateDisplayedshops(); // Update displayed shops based on the new selection state
  }

  updateDisplayedshops(): void {
    if (this.showSelectedOnly) {
      this.displayedShops = this.shopsList.filter((shop: any) => shop.selected); // Show only selected shops
    } else {
      this.displayedShops = this.shopsList; // Show all shops
    }

  }

  toggleShowSelected(): void {
    this.showSelectedOnly = !this.showSelectedOnly;
    this.updateDisplayedshops(); // Update displayed shops based on the showSelectedOnly flag
  }

  showSelectedShops(): void {
    this.showAll = true;

    this.showSelectedOnly = true;
    this.updateDisplayedshops(); // Update displayed shops to show only selected shops
  }

  showAllShops(): void {
    this.showAll = true;

    this.showSelectedOnly = false;
    this.updateDisplayedshops(); // Update displayed shops to show all shops
  }


  /**
   * On Add clicked
   * Show the dialog to enter new shops
   */


  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }


  /**




  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }



  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData(sd: any): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On shops selected
 * @param shopsInfo 
 */
  onRowSelected(shopsInfo: ShopDetails): void {
    this.selectedshop = shopsInfo;
  }



  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }
  // saveImportedShop(): any {
  //   const selectedShops = [];
  //   for (let i = 0; i < this.selection.selected.length; i++) {
  //     const shopToImport = this.getShopToImport(i);
  //     if (shopToImport.ooRegionId === this.REGION_SELECT_ID) {
  //       this.selection.selected[i].hasError = true;
  //     }
  //     selectedShops.push(shopToImport);
  //   }
  //   if (selectedShops.findIndex(e => e.ooRegionId === this.REGION_SELECT_ID) > -1) {
  //     let snackBarData: SnackBarData = {
  //       message: 'Select a category',
  //       title: 'Error',
  //       type: 'error'
  //     };
  //     this.snackBarService.openSnackBar(snackBarData);
  //     return;

  //   }
  //   this.saveItem(selectedShops);

  // }
  saveImportedShop(): any {
    const selectedShops = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      const shopToImport = this.getShopToImport(i);
      if (shopToImport.ooRegionId === this.REGION_SELECT_ID) {
        this.selection.selected[i].hasError = true;
      }
      selectedShops.push(shopToImport);
    }
    if (selectedShops.findIndex(e => e.ooRegionId === this.REGION_SELECT_ID) > -1) {
     
      let snackBarData: SnackBarData = {
        message: 'Select a region.',
        title: 'Error',
        type: 'error'
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
      return;
    }
    this.saveItem(selectedShops);
   

  }


  getShopToImport(i: any): any {
    const activeUser: any = this.auth.getUser;
    const shopToImport = {
      'name': this.selection.selected[i].name,
      'code': this.selection.selected[i].code,
      'ooRegionId': this.selection.selected[i].ooRegionId,
      'description': this.selection.selected[i].description,
      'shopId': this.selection.selected[i].shopId,
      'isValid': 0,
      'createdBy': 1,
      'updatedBy': 1,
      'address': this.selection.selected[i].address,
      'companyTaxNo': this.selection.selected[i].companyTaxNo,
      'isDeleted': 0,
      'isSystem': 0,
      'email': this.selection.selected[i].email,
      'phone': this.selection.selected[i].phone,
      'shopAdminUser': {
        'shopId': this.selection.selected[i].shopId,
        'userId': 'manager',
        'password': 'manager',
        'isactive': 1
      }
    };
    return shopToImport;
  }
  //   saveItem(selectedShops: any): any {
  //     if (selectedShops.length !== 0) {
  //       this.shopsService.saveImportedShopDetails(selectedShops).subscribe(
  //         (response: any) => {
  //             if (response.successCode === 0) {
  //               let snackBarData: SnackBarData = {
  //                 message: 'Item saved',
  //                 title: 'Success',
  //                 type: 'success'
  //               };
  //               this.snackBarService.openSnackBar(snackBarData);
  //               this.loadData();
  //             } else {
  //               let snackBarData: SnackBarData = {
  //                 message: response.message,
  //                 title: 'Error',
  //                 type: 'error'
  //               };
  //               this.snackBarService.openSnackBar(snackBarData);
  //             }
  //           },
  //           (error: any) => {
  //             console.log(error);
  //             let snackBarData: SnackBarData = {
  //               message: 'Something went wrong',
  //               title: 'Error',
  //               type: 'error'
  //             };
  //             this.snackBarService.openSnackBar(snackBarData);
  //           }
  //         );}
  //   }
  // }
  saveItem(selectedShops: any): any {
    if (selectedShops.length !== 0) {
      this.shopsService.saveImportedShopDetails(selectedShops).subscribe(
        (response: any) => {
          if (response.successCode === 0) {
            let snackBarData: SnackBarData = {
              message: 'Item saved',
              title: 'Success',
              type: 'success'
            };
            this.snackBarService.openSnackBar(snackBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Error',
              type: 'error'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        (error: any) => {
          console.log(error);
          let snackBarData: SnackBarData = {
            message: 'Something went wrong',
            title: 'Error',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      );
    }
  }
  // saveImportedshop(): any {
  //   const selectedshops = [];
  //   for (let i = 0; i < this.selection.selected.length; i++) {
  //     const shopToImport = this.getshopToImport(i);
  //     if (shopToImport.ooshopCategoryId === this.CATEGORY_SELECT_ID) {
  //       this.selection.selected[i].hasError = true;
  //     }
  //     selectedshops.push(shopToImport);
  //   }

  //   if (selectedshops.findIndex(e => e.ooshopCategoryId === this.CATEGORY_SELECT_ID) > -1) {
  //     let snackBarData: SnackBarData = {
  //       message: 'Select a category',
  //       title: 'Error',
  //       type: 'error'
  //     };
  //     this.snackBarService.openSnackBar(snackBarData);
  //     return;
  //   }

  //   this.saveshop(selectedshops);
  // }

  // getshopToImport(i: any): any {
  //   const activeUser: any = this.auth.getUser();
  //   const shopToImport = {
  //     'name': this.selection.selected[i].name,
  //     'code': this.selection.selected[i].code,
  //     'ooshopCategoryId': this.selection.selected[i].ooshopCategoryId,
  //     'fixedPrice': this.selection.selected[i].fixedPrice,
  //     'saleshopId': this.selection.selected[i].saleshopId,
  //     'isCustomizable': (this.selection.selected[i].shopType === 2) ? 1 : 0,
  //     'isValid': 0,
  //     'createdBy': activeUser.id,
  //     'updatedBy': activeUser.id,
  //     'displayOrder': 1,
  //     'isDeleted': 0,
  //     'isSystem': 0,
  //     'nutritionalInfo': '',
  //     'preparationTime': 0
  //   };
  //   return shopToImport;
  // }

  // saveshop(selectedshops: any): any {
  //   if (selectedshops.length !== 0) {
  //     this.shopsService.saveImportedshopDetails(selectedshops).subscribe(
  //       (response: any) => {
  //         if (response.successCode === 0) {
  //           let snackBarData: SnackBarData = {
  //             message: 'shop saved',
  //             title: 'Success',
  //             type: 'success'
  //           };
  //           this.snackBarService.openSnackBar(snackBarData);
  //           this.loadData();
  //         } else {
  //           let snackBarData: SnackBarData = {
  //             message: response.message,
  //             title: 'Error',
  //             type: 'error'
  //           };
  //           this.snackBarService.openSnackBar(snackBarData);
  //         }
  //       },
  //       (error: any) => {
  //         console.log(error);
  //         let snackBarData: SnackBarData = {
  //           message: 'Something went wrong',
  //           title: 'Error',
  //           type: 'error'
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     );
  //   } else {
  //     let snackBarData: SnackBarData = {
  //       message: 'Please select shops',
  //       title: 'Error',
  //       type: 'error'
  //     };
  //     this.snackBarService.openSnackBar(snackBarData);
  //   }
  // }
}
