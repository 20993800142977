import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Output } from '@angular/core';
import { AppConstants } from '../../app.constants';
import { ToolbarService } from './toolbar.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: false
})
export class ToolbarComponent {

@Output() toggleSidenav = new EventEmitter<void>();
isHandset = false;
showUserInfo:boolean=false;
showExtraPageMenu:boolean=false;
user: any;

constructor(private breakpointObserver: BreakpointObserver, private toolbarService:ToolbarService) {
  this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
    this.isHandset = state.matches;
  });
  let sesUser=sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
  this.user=(sesUser!==null)?JSON.parse(sesUser):undefined;

}

toggle(): void {

  this.toolbarService.onToggleOptionBar();
}

}

