
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../app.constants';
import { SystemSettingService } from '../services/system-setting.service';

@Pipe({
    name: 'OODate',
    standalone: false
})
export class OODatePipe  implements PipeTransform {
  transform(value: any, ...args: any[]) {
    throw new Error('Method not implemented.');
  }
  dateSettings: any;
  dateval:any;
  dateFormat: any;
  // constructor(private override locale:string,private systemSettingsService:SystemSettingService) {
  //   super(locale);
  // }

  // constructor(private systemSettingsService:SystemSettingService) {
  //   this.dateFormat=this.systemSettingsService.getSystemParamValue('date_format');
  //    super();
  // }
  
  //  transform(value:any, args?: any): any {
  //   var format='dd/M/yyyy (EEE)';
  //   return super.transform(value,format);
    // switch (this.dateFormat) {
    //   case 1:
    //     return  this.transform(systemDateSettings, 'dd/M/yyyy (EEE)');
    //   case 2:
    //     return this.transform(systemDateSettings,'MMMM dd, yyyy');
    //   default:
    //     return "";
    // }
   
//  }
}