<app-masters-optionbar> </app-masters-optionbar>
<div class="edit-page-root-container company-edit-root-container page-root-container">

    <div class="page-content">

        <div class="page-header">

            <div class="page-title">
                <img class="icon" src="assets/images/menu/svg/company.svg">
                <span>{{title}}</span>
            </div>
            <div class="action-btn-container">
                @if(permission.can_edit){
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="actionSaveBussiness()">SAVE</button>
                }
            </div>
        </div>

        <!-- <mat-tab-group style="background: transparent;">
            <mat-tab label="Sale items" style="text-align: left;"> -->
        <div class="page-details">

            <form class="edit-form" [formGroup]="editForm">
                <mat-tab-group style="background: #fff;" [selectedIndex]="getSelectedIndex()">


                    <mat-tab label="General">
                        <div class="detail-section">
                            <div class="content-container">
                                <mat-form-field class="last_name">
                                    <mat-label>Name</mat-label>
                                    <input formControlName="name" maxlength="150" matInput placeholder="Name"
                                        type="text" [(ngModel)]="companyProvider?.data.name">
                                    <mat-error *ngIf="editForm.controls['name'].hasError('required')">This field should
                                        be entered.</mat-error></mat-form-field>

                                <mat-form-field class="description">
                                    <mat-label>Address</mat-label>
                                    <textarea matInput formControlName="address" maxlength="255"
                                        [(ngModel)]="companyProvider?.data.address" placeholder="Address"></textarea>
                                    <mat-error *ngIf="editForm.controls['address'].hasError('required')">This field
                                        should be entered.</mat-error>
                                </mat-form-field>

                                <div class="section-detail">
                                    <div class="input-left">
                                        <mat-form-field class="state section-rows">
                                            <mat-label>City</mat-label>
                                            <input matInput type="text" [(ngModel)]="companyProvider?.data.city"
                                                type="text" formControlName="city" placeholder="City">
                                            <mat-error *ngIf="editForm.controls['city'].hasError('required')">This field
                                                should be entered.</mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="input-right">
                                        <mat-form-field class="country section-rows">
                                            <mat-label>State</mat-label>
                                            <input matInput [(ngModel)]="companyProvider?.data.state" type="text"
                                                formControlName="state" placeholder="State">
                                            <mat-error *ngIf="editForm.controls['state'].hasError('required')">This
                                                field should be entered.</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="section-detail">
                                    <div class="input-left">
                                        <mat-form-field class="country section-rows">
                                            <mat-label>Country</mat-label>
                                            <input matInput [(ngModel)]="companyProvider?.data.country" type="text"
                                                formControlName="country" placeholder="Country">
                                            <mat-error *ngIf="editForm.controls['country'].hasError('required')">This
                                                field should be entered.</mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="input-right">
                                        <mat-form-field class="zipcode section-rows">
                                            <mat-label>Zip Code</mat-label>
                                            <input matInput [(ngModel)]="companyProvider?.data.zipCode" type="text"
                                                formControlName="zipCode" placeholder="Zip Code">
                                            <mat-error *ngIf="editForm.controls['zipCode'].hasError('required')">This
                                                field should be entered.</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="section-detail">
                                    <div class="input-left">
                                        <mat-form-field class="phone section-rows">
                                            <mat-label>Phone</mat-label>
                                            <input matInput [(ngModel)]="companyProvider?.data.phone" type="text"
                                                formControlName="phone" placeholder="Phone" maxlength="10"
                                                (keypress)="numbersOnly($event)">
                                            <mat-error *ngIf="editForm.controls['phone'].hasError('required')">This
                                                field should be entered.</mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="input-right">
                                        <mat-form-field class="last_name section-rows">
                                            <mat-label>Domain</mat-label>
                                            <input [(ngModel)]="companyProvider?.data.domain" formControlName="domain"
                                                matInput placeholder="Domain" type="text">
                                            <mat-error *ngIf="editForm.controls['domain'].hasError('required')">This
                                                field should be entered.</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="section-detail">
                                    <div class="input-left">
                                        <mat-form-field class="email section-rows">
                                            <mat-label>Email</mat-label>
                                            <input matInput [(ngModel)]="companyProvider?.data.email"
                                                formControlName="email" placeholder="Email" type="email">
                                            <mat-error *ngIf="editForm.controls['email'].hasError('required')">This
                                                field should
                                                be entered.</mat-error>
                                                <mat-error *ngIf="editForm.controls['email'].hasError('email')">This
                                                    is not a valid email.</mat-error>
                                            <!-- <mat-error *ngIf="editForm.controls['email'].hasError('email')">Please enter a valid email address</mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="input-right">
                                        <mat-form-field class="email section-rows">
                                            <mat-label>Support Email</mat-label>
                                            <input matInput [(ngModel)]="companyProvider?.data.supportEmail"
                                                formControlName="supportEmail" placeholder="Support Email" type="email">
                                            <mat-error *ngIf="editForm.controls['supportEmail'].hasError('required')">This
                                                field should
                                                be entered.</mat-error>
                                                <mat-error *ngIf="editForm.controls['supportEmail'].hasError('pattern')">
                                                    Enter valid email addresses separated by commas (no spaces).
                                                </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </mat-tab>

                    <mat-tab label="Settings">
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="form-content-container">
                                    <div class="input-group">
                                        <div class="business-type-form">
                                            <h2 class="mat-headline">Bussiness Types</h2>
                                        </div>
                                        <div style="width: 100%">
                                            <table style="width: 100%">
                                                <tr>
                                                    <td> </td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td class="min-amount-head"> </td>
                                                    <td class="max-amount-head"></td>
                                                    <td> </td>
                                                    <td> </td>
                                                </tr>


                                                <tr
                                                    *ngFor="let businessType of companyProvider?.data.businessTypes let i = index">
                                                    <td class="chk-enable">
                                                        <mat-checkbox formControlName="businessTypesCheckBox{{i+1}}"
                                                            [(ngModel)]="businessType.isValid"> </mat-checkbox>
                                                    </td>

                                                    <td class="tdinput1 biz-type-name">

                                                        <mat-form-field style="width:275px;">
                                                            <mat-label>Name</mat-label>
                                                            <input matInput placeholder="Name" type="text"
                                                                formControlName="businessTypesName{{i+1}}"
                                                                [(ngModel)]="businessType.name" style="width: 275px;">
                                                        </mat-form-field>
                                                    </td>
                                                    <!-- <td class="tdinput1 biz-type-desc">
                                        <mat-form-field>
                                            <input formControlName="businessTypesDescription{{i+1}}" matInput
                                                placeholder="Description" type="text"
                                                [(ngModel)]="businessType.description">
                                        </mat-form-field>
                                    </td> -->
                                                    <td class="tdinput1 biz-type-min-amount">
                                                        <mat-form-field>
                                                            <mat-label>Min Amt</mat-label>
                                                            <input formControlName="businessTypesMinOrder{{i+1}}"
                                                                matInput placeholder="Min Amount" type="text"
                                                                (keypress)="allowNumbersOnly($event)"
                                                                [(ngModel)]="businessType.amountMinLimit"
                                                                class="custom-placeholder-size">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="tdinput1 biz-type-max-amount">
                                                        <mat-form-field>
                                                            <mat-label>Max Amt (Same Day)</mat-label>
                                                            <input formControlName="businessTypesMaxOrderSameDay{{i+1}}"
                                                                matInput placeholder="Max Amount (Same Day)" type="text"
                                                                (keypress)="allowNumbersOnly($event)"
                                                                [(ngModel)]="businessType.amountMaxLimitSameDay">
                                                        </mat-form-field>
                                                    </td>

                                                    <td class="tdinput1 biz-type-max-amount">
                                                        <mat-form-field>
                                                            <mat-label>Max Amt (Future)</mat-label>
                                                            <input formControlName="businessTypesMaxOrder{{i+1}}"
                                                                matInput placeholder="Max Amount (Future)" type="text"
                                                                (keypress)="allowNumbersOnly($event)"
                                                                [(ngModel)]="businessType.amountMaxLimit">

                                                        </mat-form-field>
                                                    </td>
                                                    <td class="tdinput1 biz-type-init-delay">
                                                        <mat-form-field>
                                                            <mat-label>Initial Delay (min)</mat-label>
                                                            <input formControlName="businessTypesInitialDelay{{i+1}}"
                                                                matInput placeholder="Initial Delay (min)" type="text"
                                                                (keypress)="allowNumbersOnly($event)"
                                                                [(ngModel)]="businessType.intialDelay">

                                                        </mat-form-field>
                                                    </td>

                                                    <!-- <td class="tdinput1 biz-type-disp-order">
                                                        <mat-form-field>
                                                            <mat-label>Display Order</mat-label>
                                                            <input formControlName="businessTypesDisplayOrder{{i+1}}"
                                                                matInput placeholder="Display Order" type="text"  (keypress)="allowNumbersOnly($event)"
                                                                [(ngModel)]="businessType.displayOrder">
                                                        </mat-form-field>
                                                    </td> -->
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="form-content-container">

                                    <div class="business-type-form">
                                        <h2 class="mat-headline">Order Service</h2>
                                    </div>
                                    <div style="width: 100%">
                                        <table style="width: 100%">
                                            <tr>
                                                <td> </td>
                                                <td></td>
                                                <td></td>
                                                <td> </td>
                                                <td> </td>
                                            </tr>

                                            <tr
                                                *ngFor="let orderService of companyProvider?.data.orderServices let i = index">
                                                <td class="chk-enable">
                                                    <mat-checkbox formControlName="OrderServiceCheckBox{{i+1}}"
                                                        [(ngModel)]="orderService.isValid">
                                                    </mat-checkbox>
                                                </td>
                                                <td class="tdinput1"> <mat-form-field>
                                                        <mat-label>Name</mat-label>
                                                        <input formControlName="OrderServiceName{{i+1}}" matInput
                                                            placeholder="Name" type="text"
                                                            [(ngModel)]="orderService.name">
                                                    </mat-form-field> </td>

                                                <td class="tdinput1"> <mat-form-field>
                                                        <mat-label>Description</mat-label>
                                                        <input formControlName="OrderServiceDescription{{i+1}}" matInput
                                                            placeholder="Description" type="text"
                                                            [(ngModel)]="orderService.description">
                                                    </mat-form-field> </td>

                                                <td class="tdinput1 order-before"> <mat-form-field>
                                                        <mat-label>Order Before</mat-label>
                                                        <input formControlName="OrderServiceOrderBefore{{i+1}}" matInput
                                                            placeholder="Order Before" type="text"
                                                            (keypress)="allowNumbersOnly($event)"
                                                            [(ngModel)]="orderService.advOrderDays">
                                                    </mat-form-field> </td>

                                                <td class="tdinput1 ordr-disp-order"> <mat-form-field>
                                                        <mat-label>Display Order</mat-label>
                                                        <input formControlName="OrderServiceDisplayOrder{{i+1}}"
                                                            matInput placeholder="Display Order" type="text"
                                                            (keypress)="allowNumbersOnly($event)"
                                                            [(ngModel)]="orderService.displayOrder">
                                                    </mat-form-field> </td>
                                                <td class="tdinput1">
                                                    <button mat-flat-button color="primary"
                                                        (click)="viewDefaultTiming(orderService)"
                                                        class="view-timeing-btn">View Timing</button>
                                                </td>


                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </mat-tab>
                    <mat-tab label="Others">
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="business-type-form">
                                    <h2 class="mat-headline">Other Settings</h2>
                                </div>
                                <div fxLayout="row" style="margin: 10px 10px 10px 0px;">
                                    <button style="width:200px;" mat-raised-button color="primary"
                                        (click)="actionOpenEditor('terms')">
                                        Terms and Conditions </button>
                                    <button style="width:200px;margin-left: 10px;" mat-raised-button color="primary"
                                        (click)="actionOpenEditor('faq')">FAQ</button>
                                </div>
                                <h2 class="mat-headline">Notice Settings</h2>
                                <div fxLayout="row" style="margin: 10px 10px 10px 0px;">

                                    <div fxLayout="row">
                                        <button style="width:200px;" mat-raised-button color="primary"
                                            (click)="notice.click()"> Set Notice </button>
                                        <button style="width:200px;margin-left: 10px;" mat-raised-button color="warn"
                                            (click)="resetNotice()">Remove Notice</button>
                                        <input type="file" accept="*.zip" #notice style="display:none"
                                            (change)="setNotice($event)" onclick="this.value=null;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <!-- <mat-tab label="MISC">
                        
                        <quill-editor style="height:800px"></quill-editor>
                    </mat-tab> -->
                </mat-tab-group>
            </form>

        </div>


    </div>
</div>