import { Component, ElementRef, HostListener, OnInit, ViewChild, viewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemsModel } from '../models/items.model';
import { ItemsService } from '../items.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { DepartmentService } from '../../department/department.service';
import { SESSION_MASTER_COMBO, SESSION_MASTER_CUSTMIZATION } from 'src/app/common/app.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { PermissionService } from 'src/app/auth/permission.service';


@Component({
    selector: 'app-item-edit',
    templateUrl: './item-edit.component.html',
    styleUrl: './item-edit.component.scss',
    standalone: false
})

export class ItemEditComponent {
  title: string = "New Items";
  editForm: FormGroup;
  itemInfo: ItemsModel = {
    itemCustOptionIds: [], itemCustomizationOptnInactive: [],
    businessTypes: [],
    availability: [],
    valid: false,
    inactiveTo: '',
    inactiveFrom: '',
    checked: false,
    itemCustomizationOptnValueInactive: [],
    itemComboOptionIds:[], itemComboOptnInactive:[],
    itemComboOptnValueInactive: [],
    category:[]
  };

  isFormDirty: boolean = false;
  selectedFile: File | null = null;
  previewUrl: any = null; // To store the image preview URL
  uplodFilePath: string;
  showOldImage: boolean = true; // Flag to determine whether to display old or new image
  departmentId: any;
  parentId: any;
  imageUrls: string[] = [];
  savedCustomizationOptionValues: number[] = [];
  
  // Add these new properties
  @ViewChild("inputFile") inputFile!: ElementRef;;
  @ViewChild("categoryInput") categoryInput! :  ElementRef<HTMLInputElement>;
  isAddEnabled: boolean = false;
  previewImage: any = null;
  imagetoUpload: boolean = false;
  snackBar: any;
  allCustmizationMasterDtl: any;
  itemCustomizationDetail: any;
  itemComboDetail: any;
  itemsIsOpen = false;
  currentCustomizationopt: any;
  customizationDetail: any;
  comboDetail: any;
  vaildBusinessTypes: any;
  itemBuisnessTypes: any[];
  categoryList: any;
  dummySaver!: string;
  savingRequest!: boolean;
  vaildType: { ooSaleItemId?: string; ooBusinessTypeId?: string; };
  itemDetailForm: any;
  allComboMasterDtl: any;
  filteredOptions!: string[];
  options: string[] = ['One', 'Two', 'Three', 'Four', 'Five'];
  filteredCategory:any;
  permission: any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    private departmentService: DepartmentService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
  ) {
    this.permission = permissionService.getPagePermission('item-edit');
    this.itemBuisnessTypes = [];
    this.vaildType = {};

    let itemId = this.route.snapshot.paramMap.get('id');
    this.filteredOptions = this.options.slice();
    this.editForm = this.builder.group({
      code: this.builder.control(this.itemInfo.code, [Validators.required, Validators.maxLength(50)]),
      name: this.builder.control(this.itemInfo.name, [Validators.required]),
      description: this.builder.control(this.itemInfo.description),
      nutritionalInfo: this.builder.control(this.itemInfo.nutritionalInfo),
      displayOrder: this.builder.control(this.itemInfo.displayOrder, [Validators.pattern('^[0-9]*$')]),
      fixedPrice: this.builder.control(this.itemInfo.fixedPrice),
      inactiveFrom:this.builder.control(this.itemInfo.inactiveFrom),
      inactiveTo:this.builder.control(this.itemInfo.inactiveTo),
      isValid: this.builder.control(this.itemInfo.isValid),
      isCustomizable: this.builder.control(this.itemInfo.isCustomizable),
      isCombo: this.builder.control(this.itemInfo.isCombo),
      category:this.builder.control("",[Validators.required]),
      businessTypes0: [""],
      businessTypes1: [""],
      ooItemCategoryId: "",
      day0: [""],
      day1: [""],
      day2: [""],
      day3: [""],
      day4: [""],
      day5: [""],
      day6: [""],

      availability: [
        { id: "", ooSaleItemId: "", day: "", isValid: "", isDeleted: "" }
      ],
      businessTypes: [{ ooSaleItemId: "", ooBusinessTypeId: "" }],



    });






    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;

    });

    if (itemId !== null && parseInt(itemId) !== 0) {
      this.setitemInfo(itemId);
    }
    this.uplodFilePath = environment.uplodFilePath;

  }

  ngOnInit(): void {
   
   // this.loadCustomizationDetail();
    this.isFormDirty = false;


  }
  categoryFilter(): void {
    const filterValue =this.categoryInput.nativeElement.value.toLowerCase();
    this.filteredCategory= this.categoryList.filter((cat:any) => cat.name.toLowerCase().includes(filterValue));
    if(this.filteredCategory==undefined || this.filteredCategory.length<=0){
      this.editForm.controls['category'].setValue(undefined);
    }
    this.isFormDirty = false;

  }

  catAcDisplayText(category:any):string{
    this.isFormDirty = false;

    return (category!=undefined)?category.name:this.categoryInput.nativeElement.value;
  }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
    
    this.router.navigate(['/item']);

  }

  /**
   * If tas is is null set an empty category info
   * if category id is not null call api to rtrive the info
   * @param itemId 
   */
  setitemInfo(itemId: any): void {

    let response = this.itemsService.getId(itemId).subscribe({
      next: (response) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;
          this.itemInfo = response.data;
          this.imageListProvider = this.itemInfo.saleItemImages;
          this.loadCustomizationDetail();
          this.loadBuisnessTypeData();
          this.generateNullAvaliability();
          this.sortAvailability();
          this.loadComboDetail();
          this.itemsService.getItemCategoryList().subscribe(
            (response: any) => {
              if (response.successCode === 0) {
                this.isFormDirty = false;

                this.categoryList = response.data;
                this.removeMainCategory();

                this.setSelectedCategory(this.itemInfo.ooItemCategoryId);
              }
            },
            (error: any) => { }
          );
          this.editForm.patchValue(this.itemInfo);
          this.title = this.getTitle();
          if (this.itemInfo.itemCustomizationOptnValueInactive == null) {
            this.itemInfo.itemCustomizationOptnValueInactive = [];
          }
          if (this.itemInfo.itemCustomizationOptnInactive == null) {
            this.itemInfo.itemCustomizationOptnInactive = [];
          }
          if (this.itemInfo.itemComboOptnInactive == null) {
            this.itemInfo.itemComboOptnInactive = [];
          }
          this.isFormDirty = false;
        }
        else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /***
   * Set the selected cateogry
   */
  setSelectedCategory(categoryId: any) {
    const selectedCategory = this.categoryList.find((category:any) => category.id === categoryId);
    if (selectedCategory) {
      this.editForm.controls['category'].setValue(selectedCategory);
    } else {
      console.warn(`Category with ID ${categoryId} not found`);
    }
  }

  loadCustomizationDetail() {
    var sessionData = sessionStorage.getItem(SESSION_MASTER_CUSTMIZATION);
    this.allCustmizationMasterDtl = (sessionData == null) ? undefined : JSON.parse(sessionData);
    if (this.allCustmizationMasterDtl === undefined || this.allCustmizationMasterDtl === null) {
      this.itemsService.loadCustomizationOptionMasterDtl().subscribe(
        (res: any) => { // Specify the type of 'res'
          this.isFormDirty = false;
          this.allCustmizationMasterDtl = JSON.parse(res.data);
          sessionStorage.setItem(SESSION_MASTER_CUSTMIZATION, JSON.stringify(this.allCustmizationMasterDtl));
          this.getItemCustDetailFromMaster();
        }
      );
    } else {
      this.getItemCustDetailFromMaster();
    }
  }

  removeMainCategory(): void {
    for (let index = 0; index < this.categoryList.length; index++) {
      // console.log(this.categoryList[index]);
      if (this.categoryList[index].parentId === 0) {
        this.categoryList[index] = null;
      }
    }
    this.categoryList = this.categoryList.filter(Boolean);
  }

  getItemCustDetailFromMaster() {
    /**
     * customization detail
     */
    if (this.itemInfo != undefined && this.itemInfo.itemCustOptionIds != undefined && this.itemInfo.itemCustOptionIds.length > 0) {
      this.itemCustomizationDetail = this.allCustmizationMasterDtl.filter((ac: { custOptId: any; }) =>
        this.itemInfo.itemCustOptionIds.find((e: any) => e === ac.custOptId)
      );
      if (this.itemCustomizationDetail !== undefined && this.itemCustomizationDetail.length > 0) {
        this.itemCustomizationDetail = JSON.parse(JSON.stringify(this.itemCustomizationDetail));
        this.getItemCustOption();
      }
    }
  }

  
  
  sortAvailability(): void {
    this.itemInfo.availability.sort(function (a: any, b: any): any {
      return a.day - b.day;
    })
    this.isFormDirty = false;;
  }
  

  generateNullAvaliability(): void {

    for (let index = 1; index <= 7; index++) {
      const isAvailable = this.itemInfo.availability.findIndex(function (
        el: any
      ): any {
        return el.day === index;
      });
      if (isAvailable === -1) {
        this.itemInfo.availability.push({
          day: index,
          isDeleted: 0,
          isValid: 0,
          ooSaleItemId: this.itemInfo.id
        });
      }
    }
    this.isFormDirty = false;

  }

  getItemCustOption() {
    this.customizationDetail = [];
    for (let options of this.itemCustomizationDetail) {
      let inactiveCustOpt = this.itemInfo.itemCustomizationOptnInactive.some((ici: any) => ici.customizationoptionId === options.custOptId); // Specify the type of 'ici'
      const custOption = options;
      custOption.isSelected = (!inactiveCustOpt) ? true : false;
      custOption.isOpen = false;
      this.customizationDetail.push(custOption);
    }
    this.isFormDirty = false;

  }

  
  
  loadBuisnessTypeData(): void {
    this.itemsService.getBusinessType().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;
          const businessTypes = response.data;
          this.vaildBusinessTypes = businessTypes.filter((e: any) => e.isValid === 1);
          this.itemBuisnessTypes = [];
          for (const busType of this.vaildBusinessTypes) {
            const isBuisTypeValid =
              this.itemInfo.businessTypes !== null &&
                this.itemInfo.businessTypes !== undefined
                ? this.itemInfo.businessTypes.findIndex(
                  e => e.ooBusinessTypeId === busType.id
                ) >= 0
                : false;
            const itemBisType = {
              name: busType.name,
              ooSaleItemId: this.itemInfo.id,
              ooBuisnessTypeId: busType.id,
              isValid: isBuisTypeValid
            };
            this.itemBuisnessTypes.push(itemBisType);
          }
        }
      },
      (error: any) => { }
    );
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.itemInfo.id == 0) ? "New category" : "Editing : " + this.itemInfo.name;
    return title;
  }



  actionAddImage(): void {
    this.isAddEnabled = true;
  }

  async importFile(eventData: any): Promise<void> {
    const file = <File>eventData.target.files[0];
    await this.getBase64(file).then(async (data: any) => {
      this.previewImage = data;
      const result = await this.getImageDimensions(this.previewImage);
      // console.log(result);
      if (
        result.h <= environment.itemImageHeight &&
        result.w <= environment.itemImageWidth
      ) {
        this.imagetoUpload = true;
      } else {
        this.imagetoUpload = false      }
    });
    const fd = new FormData();
    fd.append("ooSaleItemId", this.itemInfo.id);
    fd.append("itemImage", file);
    if (this.imagetoUpload === true) {
      this.itemsService.uplaodItemImage(fd).subscribe(
        (response: any) => {
          if (response.successCode === 0) {

            this.isFormDirty = false;
              let snackBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'Item Image Uploaded',
              };
              this.snackBarService.openSnackBar(snackBarData);
            this.isAddEnabled = false;
            this.imageListProvider = response.data;

            this.previewImage = "assets/images/sample_image.png";
            this.resetFile();
          }
        },
        (error: any) => {
          // console.log(error);
        }
      );
    } else {
      let snackBarData: SnackBarData = {
        title: 'Failed!!!',
        type: 'error',
        message:  `Please upload image with a dimension less than ${environment.itemImageHeight} x ${environment.itemImageWidth}`,
        
      };
      this.snackBarService.openSnackBar(snackBarData);
    }
  }
  resetFile(): void {
    this.inputFile.nativeElement.value = "";
    this.isFormDirty = false;


  }
  getImageDimensions(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const i = new Image();
      i.onload = function (): void {
        resolve({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  getBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  imageListProvider: any[] = []; // Declare imageListProvider property
  imageListPath: string = environment.uplodFilePath + "images/product/"; // Declare imageListPath property

  // ... existing methods

  changeDefaultImage(event: any, data: any, position: number): void {
    // Define changeDefaultImage method logic
    for (let i = 0; i < this.imageListProvider.length; i++) {
      if (i === position) {
        this.imageListProvider[i].isDefault = 1;
      } else {
        this.imageListProvider[i].isDefault = 0;
      }
    }
    this.isFormDirty = false;

  }

  // actionDelete(imageData: any): void {
  //   // Define actionDelete method logic
  //   const confirmDelete = confirm("Are you sure to delete the photo ?");
  //   if (confirmDelete) {
  //     this.itemsService.deleteItemImage([imageData]).subscribe(
  //       (response: any) => {
  //         if (response.successCode === 0) {
  //           //this.loadData();
  //           //   if (this.toastrService.toasts.length < 1) {
  //           //     this.toastrService.success("Item Image Deleted", "Success");
  //           //   }
  //         }
  //       },
  //       (error: any) => { }
  //     );
  //   }
  // }
  actionDelete(imageData: any): void {
    const message = `Are you sure, you want to delete the photo?`;
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);
    const deleteId = this.itemInfo.id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.itemsService.deleteItemImage([imageData]).subscribe({
          next: (response: any) => {
            if (response.successCode === 0) {
              this.setitemInfo(deleteId);  
              this.isFormDirty = false;
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'Item Image Deleted.'
              }
              this.snackBarService.openSnackBar(snckBarData);
             // this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (_error: any) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
  actionUpdate(): void {
    Object.assign(this.itemInfo, this.editForm.value);
                                                                                                                                                                                                                                                                                                                                                                     
    this.itemInfo.saleItemImages = this.imageListProvider;
    if (this.itemInfo.isValid === false) {
      this.itemInfo.isValid = 0;
    } else if (this.itemInfo.isValid === true) {
      this.itemInfo.isValid = 1;
    }
    for (let i = 0; i < this.itemInfo.availability.length; i++) {
      if (this.itemInfo.availability[i].isValid === false) {
        this.itemInfo.availability[i].isValid = 0;
      } else if (this.itemInfo.availability[i].isValid === true) {
        this.itemInfo.availability[i].isValid = 1;
      }
    }
    /**
     * disable item customization option.
     */
    this.itemInfo.itemCustomizationOptnInactive = [];
    if (this.itemCustomizationDetail !== undefined) {
      this.itemCustomizationDetail.forEach((element: any) => {
        if (!element.isSelected) {
          const custOpttInactiveByItem = {
            saleItemId: this.itemInfo.saleItemId,
            customizationoptionId: element.custOptId
          };
          this.itemInfo.itemCustomizationOptnInactive.push(custOpttInactiveByItem);
        }
      });

      this.itemInfo.isCustomizable = (this.itemInfo.isCustomizable) ? 1 : 0;
      if (this.itemInfo.isCustomizable === 1) {
        if (this.itemCustomizationDetail.length > 0 &&
          (this.itemCustomizationDetail.length === this.itemInfo.itemCustomizationOptnInactive.length)) {
          let snackBarData: SnackBarData = {
            message: "Customisation is enabled, but no options are selected. Please select at least one option or disable customization for this item.",
            title: 'Error',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    }



    this.itemInfo.itemComboOptnInactive = [];
    if (this.itemComboDetail !== undefined) {
      this.itemComboDetail.forEach((element: any) => {
        if (!element.isSelected) {
          const comboOpttInactiveByItem = {
            saleItemId: this.itemInfo.saleItemId,
            comboOptionId: element.comboOptId
          };
          this.itemInfo.itemComboOptnInactive.push(comboOpttInactiveByItem);
        }
      });

      this.itemInfo.isCombo = (this.itemInfo.isCombo) ? 1 : 0;
      if (this.itemInfo.isCombo === 1) {
        if (this.itemComboDetail.length > 0 &&
          (this.itemComboDetail.length === this.itemInfo.itemComboOptnInactive.length)) {
          let snackBarData: SnackBarData = {
            message: "Combo is enabled, but no options are selected. Please select at least one option or disable combo for this item.",
            title: 'Error',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    }

    this.savingRequest = true;
    this.itemInfo.businessTypes = [];
    const validBusinessType = this.itemBuisnessTypes.filter(
      e => e.isValid === true
    );
    for (let j = 0; j < validBusinessType.length; j++) {
      this.vaildType = { ooSaleItemId: "", ooBusinessTypeId: "" };
      this.vaildType.ooSaleItemId = validBusinessType[j].ooSaleItemId;
      this.vaildType.ooBusinessTypeId = validBusinessType[j].ooBuisnessTypeId;
      this.itemInfo.businessTypes.push(this.vaildType);
    }
    // if (this.itemDetailForm.status === "INVALID") {
    //   this.savingRequest = false;
    //   // if (this.snackbarService.snackbars.length < 1) {
    //   //   this.snackbarService.error("Please enter a valid inputs", "Invalid");
    //   // }
    //   return;
    // }
    // if (
    //   this.validValidation.fromDate.length > 0 ||
    //   this.validValidation.toDate.length > 0
    // ) {
    // if (this.validValidation.fromDate.length > 0) {
    //   if (this.snackbarService.snackbars.length < 1) {
    //     this.snackbarService.error(this.validValidation.fromDate[0], "Error");
    //   }
    // }
    // if (this.validValidation.toDate.length > 0) {
    //   // if (this.snackbarService.snackbars.length < 1) {
    //   //   this.snackbarService.error(this.validValidation.toDate[0], "Error");
    //   // }
    // }
    //   this.savingRequest = false;
    //   return;
    // }
    this.itemInfo.ooItemCategoryId=this.itemInfo.category.id;
    this.dummySaver = JSON.stringify(this.itemInfo);
    this.dummySaver = JSON.parse(this.dummySaver);
    // this.dummySaver.inactiveFrom = this.incactiveFromDummy;
    // this.dummySaver.inactiveTo = this.inactiveToDummy;
    // if (this.dummySaver.inactiveFrom !== "") {
    //   // this.dummySaver.inactiveFrom = this.dummySaver.inactiveFrom.substring(0, 9);
    // }
    // if (this.dummySaver.inactiveTo !== "") {
    //   // this.dummySaver.inactiveTo = this.dummySaver.inactiveTo.substring(0, 9);
    // }
    this.itemsService.saveItemDetails(this.dummySaver).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;
          let snackBarData: SnackBarData = {
            message: "Item Details Updated",
            title: 'Success',
            type: 'success'
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.savingRequest = false;
          this.savingRequest = false;
          this.router.navigate(['items']);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.savingRequest = false;
        }
      },
      (error: any) => {
        console.log(error);
        this.snackBarService.error("Something went wrong");
      }
    );
  }
  isValueEnabled(custOptValId: number): boolean {

    // If the array is empty, return true (checked)
    if (this.itemInfo.itemCustomizationOptnValueInactive.length === 0) {
      return true;
    }
  
    // Check if the custOptValId exists in the itemCustomizationOptnValueInactive array
    const isInactive = this.itemInfo.itemCustomizationOptnValueInactive.some(
      (item: any) => item.customizationOptionValueId === custOptValId
    );
  
    // Return true if the option is not in the inactive list, false otherwise
    return !isInactive;
  }

  
  onCheckboxChange(event: MatCheckboxChange, custOptValId: number): void {
    if (!event.checked) {
      if (!this.itemInfo.itemCustomizationOptnValueInactive.some((optVal:any) =>optVal.customizationOptionValueId == custOptValId)){
        const optValue = {
          saleItemId :this.itemInfo.saleItemId,
          customizationOptionValueId:custOptValId

        }
        this.itemInfo.itemCustomizationOptnValueInactive.push(optValue);

      } 
      }
      else{
        if (this.itemInfo.itemCustomizationOptnValueInactive.some((optVal:any) =>optVal.customizationOptionValueId == custOptValId)){
          var index = this.itemInfo.itemCustomizationOptnValueInactive.findIndex((optVal:any) =>optVal.customizationOptionValueId == custOptValId);
          this.itemInfo.itemCustomizationOptnValueInactive.splice(index,1);

  
        } 

      }
  }
  // isCustOptValueValid(custOptVal: number):boolean{
  //   var isValid=true;
  //   if((custOptVal.saleItemId !== null && custOptVal.saleItemId !== undefined) && (custOptVal.ooSaleItemId === null || custOptVal.ooSaleItemId === undefined)){
  //     isValid=false;
  //   }
    
  //   return isValid
  // }
  onOptionChange(event: any, customizationData: any) {
    customizationData.isSelected = event.checked;
    if (!event.checked) {
      if (!this.itemInfo.itemCustomizationOptnInactive.some((optVal:any) =>optVal.customizationoptionId == customizationData.custOptId)){
        const optValue = {
          saleItemId :this.itemInfo.saleItemId,
          customizationoptionId:customizationData.custOptId

        }
        this.itemInfo.itemCustomizationOptnInactive.push(optValue);

      } 
      }
      else{
        if (this.itemInfo.itemCustomizationOptnInactive.some((optVal:any) =>optVal.customizationoptionId == customizationData.custOptId)){
          var index = this.itemInfo.itemCustomizationOptnInactive.findIndex((optVal:any) =>optVal.customizationoptionId == customizationData.custOptId);
          this.itemInfo.itemCustomizationOptnInactive.splice(index,1);

  
        } 

      }
  }

  getErrorDescription(controlName: string): string | null {
    var message = null;
    const errors = this.editForm.get(controlName)?.errors;
    if (errors) {
      if (errors['required'])
        message = "This field should be entered.";
      else if (errors['maxlength'])
        message = "Max length of the code is " + errors['maxlength'].requiredLength;
    }
    return message;
  }  






  loadComboDetail() {
    var sessionData = sessionStorage.getItem(SESSION_MASTER_COMBO);
    this.allComboMasterDtl = (sessionData == null) ? undefined : JSON.parse(sessionData);
    if (this.allComboMasterDtl === undefined || this.allComboMasterDtl === null) {
      this.itemsService.loadComboOptionMasterDtl().subscribe(
        (res: any) => { // Specify the type of 'res'
          this.isFormDirty = false;
          this.allComboMasterDtl = JSON.parse(res.data);
          sessionStorage.setItem(SESSION_MASTER_COMBO, JSON.stringify(this.allComboMasterDtl));
          this.getItemComboDetailFromMaster();
        }
      );
    } else {
      this.getItemComboDetailFromMaster();
    }

  }

  getItemComboDetailFromMaster() {
    /**
     * customization detail
     */
    if (this.itemInfo != undefined && this.itemInfo.itemComboOptionIds != undefined && this.itemInfo.itemComboOptionIds.length > 0) {
      this.itemComboDetail = this.allComboMasterDtl.filter((ac: { comboOptId: any; }) =>
        this.itemInfo.itemComboOptionIds.find((e: any) => e === ac.comboOptId)
      );
      if (this.itemComboDetail !== undefined && this.itemComboDetail.length > 0) {
        this.itemComboDetail = JSON.parse(JSON.stringify(this.itemComboDetail));
        this.getItemComboOption();
      }
    }
    this.isFormDirty = false;

  }

  getItemComboOption() {
    this.comboDetail = [];
    for (let options of this.itemComboDetail) {
      let inactiveComboOpt = this.itemInfo.itemComboOptnInactive.some((ici: any) => ici.comboOptionId === options.comboOptId); // Specify the type of 'ici'
      const comboOption = options;
      comboOption.isSelected = (!inactiveComboOpt) ? true : false;
      comboOption.isOpen = false;
      this.comboDetail.push(comboOption);
    }
    this.isFormDirty = false;

  }

  onComboOptionChange(event: any, comboData: any) {
    comboData.isSelected = event.checked;
    if (!event.checked) {
      if (!this.itemInfo.itemComboOptnInactive.some((optVal:any) =>optVal.comboOptionId == comboData.comboOptId)){
        const optValue = {
          saleItemId :this.itemInfo.saleItemId,
          comboOptionId:comboData.comboOptId

        }
        this.itemInfo.itemComboOptnInactive.push(optValue);

      } 
      }
      else{
        if (this.itemInfo.itemComboOptnInactive.some((optVal:any) =>optVal.comboOptionId == comboData.comboOptId)){
          var index = this.itemInfo.itemComboOptnInactive.findIndex((optVal:any) =>optVal.comboOptionId == comboData.comboOptId);
          this.itemInfo.itemComboOptnInactive.splice(index,1);

  
        } 

      }
  }
  
 isComboValueEnabled(comboOptValId: number): boolean {

    // If the array is empty, return true (checked)
    if (this.itemInfo.itemComboOptnValueInactive.length === 0) {
      return true;
    }
  
    // Check if the custOptValId exists in the itemCustomizationOptnValueInactive array
    const isInactive = this.itemInfo.itemComboOptnValueInactive.some(
      (item: any) => item.comboOptionValueId === comboOptValId
    );
  
    // Return true if the option is not in the inactive list, false otherwise
    return !isInactive;
  }
  

  onComboCheckboxChange(event: MatCheckboxChange, comboOptValId: number): void {
    if (!event.checked) {
      if (!this.itemInfo.itemComboOptnValueInactive.some((optVal:any) =>optVal.comboOptionValueId == comboOptValId)){
        const optValue = {
          saleItemId :this.itemInfo.saleItemId,
          comboOptionValueId:comboOptValId

        }
        this.itemInfo.itemComboOptnValueInactive.push(optValue);

      } 
      }
      else{
        if (this.itemInfo.itemComboOptnValueInactive.some((optVal:any) =>optVal.comboOptionValueId == comboOptValId)){
          var index = this.itemInfo.itemComboOptnValueInactive.findIndex((optVal:any) =>optVal.comboOptionValueId == comboOptValId);
          this.itemInfo.itemComboOptnValueInactive.splice(index,1);

  
        } 

      }
  }

  clearCategory() {
    this.editForm.controls['category'].setValue('');
  }
  
}

