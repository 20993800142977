import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatDrawer, MatDrawerMode, MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';


@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SidenavComponent {

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  mode: MatDrawerMode = 'side'; //| 'over' = 'side';
  isExpanded: boolean;
  isHandset: boolean = false;
  showRewardMenu:boolean= false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isExpanded = false;
  }

  ngOnInit(): void {

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mode = 'over';
        this.sidenav.close();
        this.isHandset = true;
        this.isExpanded = true;
      } else {
        this.mode = 'side';
        this.sidenav.open();
        this.isHandset = false;
        this.isExpanded = false;
      }
    });

  }

  closeSidenav(): void {

    if (this.isHandset) {
      this.sidenav.close();
    } else {
      // this.isExpanded = false;
    }
  }

  toggleSidenav(): void {

    if (this.isHandset) {
      this.sidenav.toggle().then((result: MatDrawerToggleResult) => {
        if (!result) {
          this.sidenav.close();
        }
      });
    } else{
      this.sidenav.close();
     
      setTimeout(() => {
        this.isExpanded = !this.isExpanded;
        this.sidenav.open();
      }, 250);
     
    }
    
  }

}
