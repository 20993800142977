import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/common/app.constants';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userId: string = "";
  password: string = "";
  version=environment.ver;
  buildNo=environment.buildNo;
  //response: { status: number, data?: any } = { status: 0 };

  constructor(private builder: FormBuilder,
    private router: Router, private loginService: LoginService,
    private snackBarService: SnackBarService
  ) {
    sessionStorage.clear();
    this.loginForm = this.builder.group({
      'userName': ['', Validators.required],
      'password': ['', Validators.required]
    });

  }
  ngOnInit(): void {
    this.loginService.getAuthToken();

    

  }

  /**
   * Validate the application credentials and navigate to user login
   */
  authenticate() {
    
    //this.response = { status: 0 };
    const requestParams = this.loginForm.value;
    const autherization = this.loginService.validateLogin(requestParams)
    .subscribe( (response:any) => {
      if (response.status === 'SUCCESS') {
        this.loginService.setLoggin(response.data);
        this.router.navigate(['/home']);
        // login success action gose here
      } else {
 
         // this.response.status = 401;
          let snackBarData: SnackBarData = {
            title: "Failed",
            type: 'error',
            message: response.message
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
        
      } 
             //login error action gose here
      
    )
    
    
    
  }


  

  /**
   * Validate the user credentials and navigate to dashboard
   */
//   proceedUserLogin() {
//     if (this.loginForm.valid) {

//       let loginData = {
//         user_name: this.loginForm.get("user_id")?.value,
//         password: this.loginForm.get("password")?.value
//       };

//       this.loginService.userLogin(loginData).subscribe(
//         (        response: { status: string; data: any; token: any; message: any; }) => {
//           if (response.status == 'SUCCESS') {
//             let curUser = response.data;
//             let token = response.token;
//             sessionStorage.setItem(AppConstants.SESSION_VAR_USER, JSON.stringify(curUser));
//             sessionStorage.setItem(AppConstants.SESSION_VAR_TOKEN, token);
//             this.router.navigate(['home']);
//           } else {
//             let snackBarData: SnackBarData = {
//               title: "Login Failed.",
//               message: response.message,
//               type: 'error'
//             }
//             this.snackBarService.openSnackBar(snackBarData);
//           }
//         }
//       );
//     }
//   }
// }
}
