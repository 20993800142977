import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FloatingMenuOption } from './model/master-floating-model';

@Component({
    selector: 'app-master-floating-menu',
    templateUrl: './master-floating-menu.component.html',
    styleUrls: ['./master-floating-menu.component.scss'],
    standalone: false
})
export class MasterFloatingMenuComponent {
  showMore: boolean = false;

  @Input() menu: FloatingMenuOption = {
    add: true,
    more: true,
    export: true,
    search: true
  };

  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onExport: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  toggleShowMore(): void {
    this.showMore = !this.showMore
  }

addClick(): void{
  this.onAdd.emit();
}

searchClick(): void{
  this.onSearch.emit();
}

exportClick(): void{
  this.onExport.emit();
}

}
