import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-extra-pages-menu',
    templateUrl: './extra-pages-menu.component.html',
    styleUrls: ['./extra-pages-menu.component.scss'],
    standalone: false
})
export class ExtraPagesMenuComponent {
  @Input() show: boolean = false

}
