import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
    name: 'reversePipe',
    standalone: false
})
export class ReversePipe implements PipeTransform {
    transform(value: any): any {
      return value.slice().reverse();
    }
}
