import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerModel } from "../models/customer.model";
import { CustomerService } from "src/app/pages/customer/customer.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmDialogComponent, ConfirmDialogModel, } from "src/app/common/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { LoaderComponent } from "../../../common/components/loader/loader.component";
import { DirtyCheck } from "src/app/common/guards/form-deactivate.guard";
import { trigger } from "@angular/animations";
import { PermissionService } from "src/app/auth/permission.service";
@Component({
    selector: "app-customer-edit",
    templateUrl: "./customer-edit.component.html",
    styleUrls: ["./customer-edit.component.scss"],
    animations: [
        trigger('if', [
        // ... animation states and styles
        ])
    ],
    standalone: false
})
export class CustomerEditComponent implements DirtyCheck {
  title: string = "New Customer";
  editForm: FormGroup;
  permission: any;
  customerInfo: CustomerModel = {
    is_subscribed: 1,
    is_valid: 1,
  };

  dobMonths: { value: number; viewValue: string }[] = [
    { value: 1, viewValue: "January" },
    { value: 2, viewValue: "February" },
    { value: 3, viewValue: "March" },
    { value: 4, viewValue: "April" },
    { value: 5, viewValue: "May" },
    { value: 6, viewValue: "June" },
    { value: 7, viewValue: "July" },
    { value: 8, viewValue: "August" },
    { value: 9, viewValue: "September" },
    { value: 10, viewValue: "October" },
    { value: 11, viewValue: "November" },
    { value: 12, viewValue: "December" },
  ];
  jmonths: { value: number; viewValues: string }[] = [
    { value: 1, viewValues: "January" },
    { value: 2, viewValues: "February" },
    { value: 3, viewValues: "March" },
    { value: 4, viewValues: "April" },
    { value: 5, viewValues: "May" },
    { value: 6, viewValues: "June" },
    { value: 7, viewValues: "July" },
    { value: 8, viewValues: "August" },
    { value: 9, viewValues: "September" },
    { value: 10, viewValues: "October" },
    { value: 11, viewValues: "November" },
    { value: 12, viewValues: "December" },

    // Add the rest of the months here...
  ];

  currentYear: number = new Date().getFullYear();
  selectedDojDay!: number;
  selectedDojMonth!: number;
  selectedDojYear!: number;
  showLoader: boolean = false;

  // Define separate arrays for each section
  dojDaysOfMonth: { value: number; viewValues: string }[] = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    viewValues: i + 1 < 10 ? '0' + (i + 1) : '' + (i + 1),
  }));

  // You can use the same months array for both sections

  // You might want to generate a dynamic years array based on the current year
  yearsDoj: number[] = Array.from({ length: 101 }, (_, i) => this.currentYear - i);

  // For Date of Birth
  selectedDobDay!: number;
  selectedDobMonth!: number;
  selectedDobYear!: number;

  // Define separate arrays for each section
  dobDaysOfMonth: { value: number; viewValue: string }[] = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    viewValue: i + 1 < 10 ? '0' + (i + 1) : '' + (i + 1),
  }));

  // You can use the same months array for both sections

  // You might want to generate a dynamic years array based on the current year
  yearsDob: number[] = Array.from({ length: 101 }, (_, i) => this.currentYear - i- 1);

  isEmailEditable = false;
  updatedEmail!: string;



  isFormDirty: boolean = false;
  gender: any;

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
  ) {
    this.permission = permissionService.getPagePermission('customer');
    let customerId = this.route.snapshot.paramMap.get("id");
    this.editForm = this.builder.group({
      title: this.builder.control(this.customerInfo.title,),
      first_name: this.builder.control(this.customerInfo.first_name, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      last_name: this.builder.control(this.customerInfo.last_name,),
      gender: ['m'],// Set default value to 'male'
      address: this.builder.control(this.customerInfo.address,),
      city: this.builder.control(this.customerInfo.city,),
      state: this.builder.control(this.customerInfo.state,),
      country: this.builder.control(this.customerInfo.state,),
      post_code: this.builder.control(this.customerInfo.post_code,),
      phone_number: this.builder.control(this.customerInfo.phone_number, [
        Validators.required, Validators.pattern(/^\+?[0-9]*$/)

      ]),
      email: this.builder.control(this.customerInfo.email, [
        Validators.required,Validators.email

      ]),

      doj_day: this.builder.control(this.customerInfo.doj_day,),
      doj_month: this.builder.control(this.customerInfo.doj_month,),
      doj_year: this.builder.control(this.customerInfo.doj_year,),
      dob_day: this.builder.control(this.customerInfo.dob_day,),
      dob_month: this.builder.control(this.customerInfo.dob_month,),
      dob_year: this.builder.control(this.customerInfo.dob_year,),
      is_valid: this.builder.control(this.customerInfo.is_valid, []),
      is_subscribed: this.builder.control(this.customerInfo.is_subscribed, [])



    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (customerId !== null && parseInt(customerId) !== 0) {
      this.setCustomerInfo(customerId);
    }




  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns
   */
  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
    this.showLoader = true; // Display loader
    this.router.navigate(["/customer"]);
  }
  toggleEmailEdit() {
    this.isEmailEditable = !this.isEmailEditable;
    if (this.isEmailEditable && this.customerInfo.email) {
      this.updatedEmail = this.customerInfo.email;
    }
  }


  /**
    * check email already exists
    * update email
    * @param customerId
    */

  updateEmail(): void {
    // let  customerEmail={'id':this.customerInfo.id,'email':this.customerInfo.email};
    if (this.editForm.invalid) {
      return;
    }
    Object.assign(this.customerInfo, this.editForm.value);

    this.customerService.updateCustomerEmail(this.customerInfo).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.isEmailEditable = false;
          let snckBarData: SnackBarData = {
            type: 'success',
            title: 'Saved',
            message: 'Email ' + this.customerInfo.email + " has been updated succesfully."
          }
          this.snackBarService.openSnackBar(snckBarData);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Email Already Exists!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * If tas is is null set an empty customer info
   * if id is not null call api to rtrive the info
   * @param customerId
   */
  setCustomerInfo(customerId: any): void {
    this.customerService.getId(customerId).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.customerInfo = response.data.customer;
          this.editForm.patchValue(this.customerInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to get data from server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }




  /**
   *
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title =
      this.customerInfo?.id === 0
        ? "New Customer"
        : "Editing : " + this.customerInfo?.first_name || "No Title Available";
    return title;
  }
  getFormFieldClass() {
    return this.customerInfo.id !== undefined && this.customerInfo.id !== 0 ? 'edit-email' : 'email';
  }


  cancel(): void {
    this.router.navigate(["/customer"]);
  }
  /**
   * On save
   * if the details are valid call the api to update the changes.
   */
  save(): void {
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
    }else{ 
      Object.assign(this.customerInfo, this.editForm.value);
      this.customerService.update(this.customerInfo).subscribe({
        next: (response) => {
          if (response.status == "SUCCESS") {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: "success",
              title: "Saved",
              message:
                "The record for " +
                this.customerInfo.first_name +
                " has been updated successfully.",
            };
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['customer']);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: "Failed!!!",
              type: "error",
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: "Failed to update. Connection to the server failed",
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
      });
    }
  }


  allowNumbersOnly(event: KeyboardEvent) {
    const char = String.fromCharCode(event.charCode);
    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  }
}
