import { Component, Inject, ViewEncapsulation, NgModule } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterService } from '../../filter.service';
import { SnackBarService } from '../../../snackbar/snackbar.service';
import { SnackBarData } from '../../../snackbar/model/snackbar.model';

@Component({
    selector: 'app-search-filter-save-dialog',
    templateUrl: './search-filter-save-dialog.component.html',
    styleUrls: ['./search-filter-save-dialog.component.scss'],
    standalone: false
})
export class SearchFilterSaveDialogComponent {
  title: string;
  editForm!: FormGroup;
  searchInfo: any;

  constructor(private builder: FormBuilder,
    public dialogRef: MatDialogRef<SearchFilterSaveDialogComponent>,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public item: any,
    private snackBarService: SnackBarService) {
    this.searchInfo = JSON.parse(JSON.stringify(item));
   
    this.editForm = this.builder.group({
      name: this.builder.control(this.searchInfo.name, [Validators.required, Validators.maxLength(50)]),
      is_overwrite:this.builder.control(this.searchInfo.is_overwrite, []),
      is_favorite: this.builder.control(this.searchInfo.is_favorite, [])
    });
    this.title = 'Save Filter';

  }

  /**
  * On save
  * if the details are valid call the api to update the changes.
  */
  save(): void {

    if (this.editForm.valid) {
      Object.assign(this.searchInfo, this.editForm.value);
      this.filterService.saveFilter(this.searchInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.dialogRef.close(response.data);
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.searchInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }



  /**
   * Close the edit dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }

}
