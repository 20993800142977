import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserModel } from "../model/user.model";
import { UserService } from "../user.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DirtyCheck } from "src/app/common/guards/form-deactivate.guard";
import { ChangePasswordDialogComponent } from "../dialogs/change-password-dialog/change-password-dialog.component";
import { trigger } from "@angular/animations";

@Component({
    selector: "app-user-edit",
    templateUrl: "./user-edit.component.html",
    styleUrls: ["./user-edit.component.scss"],
    animations: [
        trigger('if', [
        // ... animation states and styles
        ])
    ],
    standalone: false
})
export class UserEditComponent implements OnInit, DirtyCheck {
  title: string = "New User";
  editForm: FormGroup;
  userInfo: UserModel = { is_valid: 1 };
  isFormDirty: boolean = false;
  isAddingNewEntry!: boolean;

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {
    let userId = this.route.snapshot.paramMap.get("id");
    this.editForm = this.builder.group(
      {
        login_id: this.builder.control(this.userInfo.login_id, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        name: this.builder.control(this.userInfo.name, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        email: this.builder.control(this.userInfo.email, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        phone: this.builder.control(this.userInfo.email, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        password: this.builder.control(this.userInfo.password, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        confirm_password: this.builder.control("", [
          Validators.required,
          Validators.maxLength(50),
        ]),
        is_valid: this.builder.control(this.userInfo.is_valid, []),
      },
      {
        validator: this.passwordMatchValidator,
      }
    );

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (userId !== null && parseInt(userId) !== 0) {
      this.setUserInfo(userId);
    }
  }

  ngOnInit(): void {
    this.checkOperationType();
  }

  isDirty(): boolean {
    return this.isFormDirty;
  }

  navBack(): void {
    this.router.navigate(["/users"]);
  }
  openChangePasswordDialog() {
    // Open the Mat Dialog for changing the password
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: "400px",
      data: {
        id: this.userInfo.id, // Pass the userId to the dialog
      },
    });

    // Subscribe to the dialog's afterClosed event to handle any actions after the dialog is closed
    dialogRef.afterClosed().subscribe((result) => {
      // Handle the result if needed
    });
  }

  setUserInfo(userId: any): void {
    this.userService.getId(userId).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.userInfo = response.data.user[0];
  
          // Update the userInfo object without confirm_password
          // const userInfoWithoutConfirmPassword = { ...this.userInfo };
          // delete userInfoWithoutConfirmPassword.confirm_password;
  
          // Patch the form with the updated user data
          this.editForm.patchValue(this.userInfo);
          this.editForm.removeControl('confirm_password');
  
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          console.error("Failed to fetch user data:", response.message);
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        console.error("Failed to get data from the server:", error);
        let snackBarData: SnackBarData = {
          message: "Failed to get data from the server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  

  getTitle(): string {
    return this.userInfo.id == 0
      ? "New User"
      : "Editing : " + this.userInfo.name;
  }
  checkOperationType(): void {
    const userId = this.route.snapshot.paramMap.get('id');
    this.isAddingNewEntry = userId === '0';
  }

  // save(): void {
  //   if (this.editForm.valid) {
  //     // Check for password match only when adding a new entry
  //     if (this.isAddingNewEntry && this.editForm.value.password !== this.editForm.value.confirm_password) {
  //       let snackBarData: SnackBarData = {
  //         message: "Passwords do not match",
  //         title: "Failed!!!",
  //         type: "error",
  //       };
  //       this.snackBarService.openSnackBar(snackBarData);
  //       return;
  //     }

  //     // Create a copy of the user data without confirm_password
  //     // const userInfoWithoutConfirmPassword = { ...this.userInfo };
  //     // delete userInfoWithoutConfirmPassword.confirm_password;
  //     // console.log('Data to be sent to the server:', userInfoWithoutConfirmPassword);

  //     // Patch the form with the modified user data
  //     this.editForm.patchValue(this.userInfo);

  //     // Your existing save logic
  //     this.userService.update(this.editForm.value).subscribe({
        
  //       next: (response) => {
  //         console.log('API Response:', response); // Log the API response

  //         if (response.status == "SUCCESS") {
  //           // handle success
  //           this.isFormDirty = false;
  //           let snckBarData: SnackBarData = {
  //             type: "success",
  //             title: "Saved",
  //             message: "The record for " + this.userInfo.name + " has been updated successfully.",
  //           };
  //           this.snackBarService.openSnackBar(snckBarData);
  //         } else {
  //           let snackBarData: SnackBarData = {
  //             message: response.message,
  //             title: "Failed!!!",
  //             type: "error",
  //           };
  //           this.snackBarService.openSnackBar(snackBarData);
  //         }
  //       },
  //       error: (error) => {
  //         // handle error
  //         let snackBarData: SnackBarData = {
  //           message: "Failed to update. Connection to the server failed",
  //           title: "Failed!!!",
  //           type: "error",
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       },
  //     });
  //   } else {
  //     if (this.isAddingNewEntry && this.editForm.value.password !== this.editForm.value.confirm_password) {
  //       let snackBarData: SnackBarData = {
  //         message: "Confirm Password and Password not matching",
  //         title: "Failed!!!",
  //         type: "error",
  //       };
  //       this.snackBarService.openSnackBar(snackBarData);
  //     }
  //   }
  // }
  save(): void {    
    
    if (this.editForm.valid) {
      this.editForm.removeControl('confirm_password');

      Object.assign(this.userInfo, this.editForm.value);

      this.userService.update(this.userInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.userInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            if (!this.userInfo.hasOwnProperty('id')) {
              this.navBack();
            }
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
    else {
          if (this.isAddingNewEntry && this.editForm.value.password !== this.editForm.value.confirm_password) {
            let snackBarData: SnackBarData = {
              message: "Confirm Password and Password not matching",
              title: "Failed!!!",
              type: "error",
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
  }
  }

  private passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get("password");
    const confirm_password = control.get("confirm_password");

    if (!password || !confirm_password) {
      return null;
    }

    return password.value === confirm_password.value
      ? null
      : { passwordMismatch: true };
  }
}
