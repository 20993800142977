import { Component } from '@angular/core';
import { AppConstants } from '../../app.constants';
import { UserInfoModel } from '../../models/user-info.model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
    standalone: false
})
export class UserInfoComponent {
  user:UserInfoModel | undefined;
  visible: boolean=false;
  
  ngOnInit(): void {
    
     let sesUser=sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    this.user=(sesUser!==null)?JSON.parse(sesUser):undefined;
    
  }



  logout(): void {
    sessionStorage.clear();
    // this.router.navigate(["login"]);
  }

}
