import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog
} from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { HolidayService } from "./holiday.service";
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder,  Validators, FormControl} from "@angular/forms";
import moment from 'moment';
import { MonthsCustomPipe } from "src/app/common/pipes/month.pipe";
import { MatSelectChange } from "@angular/material/select";

interface IValidationError{
  message?: string
}

interface ITimeValidationError extends IValidationError {
  date: string,
  timeFromHasError?: boolean,
  timeToHasError?: boolean,
 
}

interface IDepartmentValidationError extends ITimeValidationError {
  
  departmentId?: number,

};

interface IServiceValidationError extends ITimeValidationError{
  
  serviceId?: number,
  delayHasError?: boolean,

};

@Component({
    selector: "app-holiday-edit",
    templateUrl: "./app-holiday-edit.html",
    styleUrls: ["./holiday.component.scss"],
    standalone: false
})

// tslint:disable-next-line:component-class-suffix
export class HolidayEditPopUp implements OnInit {
  loading:  boolean = true;
  is_new: boolean = false;
  public festiveType: any;
  holidayType: any;
  public startDate: any;
  public startDateToDisplay: any;
  selectedShop: any;
  selectedShopId: any;
  regionShopsCtrl:any;
  selectedRegion: any;
  selectedRegionId:any;
  deptFestiveTimings: any;

  festiveTypes: { id: number; label: string; }[];
  fcTitleInput: any;
  public service: any = { id: "1", name: "", code: "" };
  public ooServiceList: any = [];
  public ooDepartmentList: any = [];
  public noDaysToShow = 1;
  public typeOfHoliday: any;
  public description: any;
  public saveStatus: boolean = false;
  public deptValidationError: IDepartmentValidationError = { date: '' };
  public serviceValidationError: IServiceValidationError = { date: '' };
  public selectedService: any;
  holidayTitle: any;
  isEditable:boolean=false;
  festiveHolidays: any;
  serviceFestiveTimings: any;
  public pageTitle: any;
  saving: boolean=false;
  selectedShopList: any=[];
  filteredRegion: any;

  constructor(
    private fb: FormBuilder,
    private holidayService: HolidayService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<HolidayEditPopUp>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.festiveType = "0";
    this.ooServiceList = [
      { id: 1, code: "PICKUP", name: "PICKUP", isActive: true, ooServiceTimeSlots: [], isValid: true },
      { id: 2, code: "DELIVERY", name: "DELIVERY", isActive: true, ooServiceTimeSlots: [], isValid: true }];
    this.selectedService = this.ooServiceList[0];
    this.ooDepartmentList = [
      { id: 1, code: "SUSHI", name: "SUSHI" },
      { id: 2, code: "BENTO", name: "BENTO" }
    ];
    this.festiveTypes = [{ id: 0, label: "Regular" }, { id: 1, label: "Closed" }, { id: 2, label: "Special Hours" }];
    this.startDate = this.data.day.date;
    this.holidayType = this.data.holidayType;
    this.selectedShop = this.data.selectedShop;
    this.selectedShopList = this.data.selectedShopList.shops;   
    this.selectedShopId = ((this.data.selectedShop == undefined) ? 0 : this.data.selectedShop.shopId);

  
  let pageTitleMain = ((this.selectedShop == undefined) ? ((this.selectedShopList[0] == 0 )?"National" :this.data.selectedShopList.name ): this.selectedShop.name);
  let pageTitleExt = (this.selectedShopList.length>1)? ' ( + other '+ (this.selectedShopList.length-1)+ ' ) ':'';
  this.pageTitle = pageTitleMain + pageTitleExt;

    this.reloadData();
    this.fcTitleInput = new FormControl('', [
      Validators.required,
    ]);
  }
  ngOnInit() { }

  
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 58)) {
      return false;
    }
    return true;

  }
  /**
   * 
   * @param event 
   * @param holidayModel 
   * @param timeModel 
   * @param type 
   */
  onChangeDeptTime(event: any, holidayModel: any, timeModel: any, type: number) {

    this.onchange(event, holidayModel, type);
    // Update the model
    if (type == 0) {
      timeModel.timeFrom = event.target.value;
    } else {
      timeModel.timeTo = event.target.value;
    }
    var error = this.validateTime(holidayModel, timeModel,false);
    if (error.date != '') {
      this.deptValidationError.date=error.date;
      this.deptValidationError.departmentId=timeModel.departmentId;
      this.deptValidationError.message=error.message;
      this.deptValidationError.timeFromHasError=error.timeFromHasError;
      this.deptValidationError.timeToHasError=error.timeToHasError;
      holidayModel.isValid = false;
    }

  }
  /**
   * 
   * @param event 
   * @param holidayModel 
   * @param timeModel 
   * @param type 
   */
  onChangeServiceTime(event: any, holidayModel: any, timeModel: any, type: number) {

    this.onchange(event, holidayModel, type);
    if (type == 0) {
      timeModel.timeFrom = event.target.value;
    } else {
      timeModel.timeTo = event.target.value;
    }
  }
  /**
   * 
   * @param event 
   * @param holidayModel 
   * @param type 
   */
  onchange(event: any, holidayModel: any, type: number): void {
    const currentValue = event.target.value;
    const hasSep=currentValue.indexOf(':');
    const valueArray = currentValue.split(":");
    var hour="";
    var min="";

    if (valueArray.length === 2){
      hour=('00'+valueArray[0]).slice(-2);
      min=('00'+valueArray[1]).slice(-2);
    }else if (valueArray.length ===1 && valueArray[0]!==""){
      hour=('00'+valueArray[0]).slice(-2);
      min="00";
    }
    if(hour!=="" && min!==""){
      event.target.value =hour+':'+min;
    }
    // if (
    //   valueArray.length === 1 &&
    //   valueArray[0] < 24 &&
    //   valueArray[0] > 9 &&
    //   valueArray[0].length === 2
    // ) {
    //   event.target.value = event.target.value + ":00";
    // } else if (
    //   valueArray.length === 1 &&
    //   valueArray[0] < 10 &&
    //   valueArray[0] > 0 &&
    //   valueArray[0].length === 1
    // ) {
    //   event.target.value = "0" + event.target.value + ":00";
    // } else if (
    //   valueArray.length === 1 &&
    //   valueArray[0] < 10 &&
    //   valueArray[0] > 0 &&
    //   valueArray[0].length === 2
    // ) {
    //   event.target.value = event.target.value + ":00";

    // }

  }

  isFestiveDay(timSlot: any): Boolean {
    var isFestiveDay = false;
    for (var index = 0; index < this.deptFestiveTimings.length; index++) {

      if (this.deptFestiveTimings[index].data == timSlot.date) {
        isFestiveDay = (this.deptFestiveTimings[index].festiveType == 2);
      }
    }

    return isFestiveDay;
  }
  /**
   * 
   */
  reloadData() {

    var dateFrom = new Date(this.startDate);
    var dateTo = new Date(this.startDate);
    dateTo.setDate(dateFrom.getDate() + (this.noDaysToShow - 1));
    var bodyParam = {
      "holidayType": [
        this.holidayType
      ],
      "dateFrom": dateFrom,
      "dateTo": dateTo,
      "shopId": (this.selectedShop == undefined ? 0 : this.selectedShop.shopId)
    };

    this.holidayService.getFestiveTimings(bodyParam).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.serviceValidationError={date:''};

          this.festiveHolidays = response.data;
          this.deptFestiveTimings = this.festiveHolidays.departmentInfo;
          this.startDateToDisplay = this.deptFestiveTimings[0].displayDate;
          this.serviceFestiveTimings = this.festiveHolidays.serviceInfo;
          this.ooServiceList = this.festiveHolidays.shopServices;
          this.ooDepartmentList = this.festiveHolidays.shopDepartments;
          this.selectedService = this.ooServiceList[0];
          this.saveStatus = false;
          this.saving = false;
          this.isEditable = (this.deptFestiveTimings[0].shopId == 0) || (this.deptFestiveTimings[0].holidayType != 1);
          this.holidayTitle = (this.holidayTitle != undefined && this.holidayTitle.trim() !== "") ? this.holidayTitle : this.deptFestiveTimings[0].description;
        }
      },
      (error: any) => { }
    );
  }


  /**
   * Copy the first department time to  all other days
   * @param fstDays 
   */
  applyToAll(fstDays:any): void {

    this.deptFestiveTimings.forEach((dft:any) => {
      dft.festiveType = fstDays.festiveType;
      dft.holidayType = fstDays.holidayType;
      dft.description = fstDays.description;
      dft.festiveDeptTimings.forEach((t:any, index:any) => {
        t.timeFrom = fstDays.festiveDeptTimings[index].timeFrom;
        t.timeTo = fstDays.festiveDeptTimings[index].timeTo;
      });
    });


  }

  /**
   * Copy the first service time to  all other days
   * @param serviceTime 
   * @param service 
   */
  applyServiceTimeToAll(serviceFestiveTimings:any, service:any) {

    let serviceTime = serviceFestiveTimings[0].timings.filter((obj:any) => obj.serviceId = service.id)[0].festiveTimeSlots;
    this.serviceFestiveTimings.forEach((sft:any) => {
      sft.timings.forEach((timeslot:any) => {

        if (timeslot.serviceId == service.id) {
          timeslot.festiveTimeSlots[0].timeFrom = serviceTime[0].timeFrom;
          timeslot.festiveTimeSlots[0].timeTo = serviceTime[0].timeTo;
          timeslot.festiveTimeSlots[0].delay = serviceTime[0].delay;
        }

      });

    });

  }

  /**
   * 
   */
  actionClose(): void {
    this.dialogRef.close();
  }
  /**
   * 
   * @param holiday 
   */
  validateFestiveHolidays(holiday: any): IDepartmentValidationError {
    var errorMsg = "";
    var validationError: IDepartmentValidationError = { date: "" };
    holiday.isValid = true;
    if (holiday.festiveType == 2) {
      for (var index = 0; index < holiday.festiveDeptTimings.length; index++) {
        var t = holiday.festiveDeptTimings[index];
        t.isValid = true;
        var error = this.validateTime(holiday, t,false);
        if (error.date != "") {
          validationError.date=error.date;
          validationError.departmentId=t.departmentId;
          validationError.message=error.message;
          validationError.timeFromHasError=error.timeFromHasError;
          validationError.timeToHasError=error.timeToHasError;
          holiday.isValid = false;
          t.isValid = false;
          break;
        }
      }
    }
    return validationError;
  }
  /**
   * Timeslots validation
   */
  validateTimeSlot():IServiceValidationError {
    var slotTimeValidator: IServiceValidationError={date:''};
    for (var hx = 0; hx < this.serviceFestiveTimings.length; hx++) {
      var timeSlot= this.serviceFestiveTimings[hx];
      for (var ix = 0; ix < timeSlot.timings.length; ix++) {
        var serviceTimeSlot=timeSlot.timings[ix];
        for (var jx = 0; jx < serviceTimeSlot.festiveTimeSlots.length; jx++) {
          var festiveTimeSlot=serviceTimeSlot.festiveTimeSlots[jx];
          var timeFrom = festiveTimeSlot.timeFrom;
          var timeTo = festiveTimeSlot.timeTo;
          var validationError = this.validateTime(timeSlot, { 'timeFrom': timeFrom, 'timeTo': timeTo },true);
          if (validationError.date != "") {
            slotTimeValidator.serviceId=serviceTimeSlot.serviceId;
            slotTimeValidator.date=validationError.date;
            slotTimeValidator.message=validationError.message;
            slotTimeValidator.timeFromHasError=validationError.timeFromHasError;
            slotTimeValidator.timeToHasError=validationError.timeToHasError;
            return slotTimeValidator;
          }
          if((timeFrom != undefined && timeFrom!='') && (timeTo!= undefined && timeTo!='')){
            if(!(festiveTimeSlot.delay>0)){
               return {date:timeSlot.date,serviceId:serviceTimeSlot.serviceId,delayHasError:true,message:"Invalid delay setting for "+timeSlot.displayDate};
            }
          }
        }
      }
    }
    return slotTimeValidator;
  }

  /**
   * 
   * @param holiday 
   * @param t 
   */
  validateTime(holiday: any, t: any, isTimeOptional:boolean): ITimeValidationError {
    var errorMsg = "";
    var validationError:ITimeValidationError = { date: holiday.date, timeFromHasError: false, timeToHasError: false, message: "" };

    if ((t.timeFrom == "" && !isTimeOptional) || (isTimeOptional && t.timeFrom == "" && t.timeTo!="")) {
      t.isValid = false;
      errorMsg = "Invalid 'Time From' is given for "+holiday.displayDate;
      validationError.message = errorMsg;
      validationError.timeFromHasError = true;
      return validationError;
    }

    if ((t.timeTo == ""  && !isTimeOptional) || (isTimeOptional && t.timeTo == "" && t.timeFrom!="")) {
      t.isValid = false;
      errorMsg = "Invalid 'Time To' is given for "+holiday.displayDate;
      validationError.message = errorMsg;
      validationError.timeToHasError = true;
      return validationError;
    }

    if (t.timeFrom!="" && (t.timeFrom.indexOf(":") < 0 || (t.timeFrom.split(":")).length < 2)) {
      t.isValid = false;
      errorMsg = "Invalid 'Time From' is given for "+holiday.displayDate;
      validationError.message = errorMsg;
      validationError.timeFromHasError = true;
      return validationError;
    }

    if (t.timeTo!="" && (t.timeTo.indexOf(":") < 0 || (t.timeTo.split(":")).length < 2)) {
      t.isValid = false;
      errorMsg = "Invalid 'Time To' is given for "+holiday.displayDate;
      validationError.message = errorMsg;
      validationError.timeToHasError = true;
      return validationError;
    }

    if(t.timeFrom!="" && t.timeT!=""){
      var timeFrom = t.timeFrom + ":00";
      var timeTo = t.timeTo + ":00";

      if(!this.checkTimeValidity(timeFrom)){
        t.isValid = false;
        errorMsg = "'Time From' s not valid for "+holiday.displayDate;
        validationError.message = errorMsg;
        validationError.timeFromHasError = true;
        return validationError;
      }

      if(!this.checkTimeValidity(timeTo)){
        t.isValid = false;
        errorMsg = "'Time To' s not valid for "+holiday.displayDate;
        validationError.message = errorMsg;
        validationError.timeToHasError = true;
        return validationError;
      }

      if (timeFrom >= timeTo) {
        t.isValid = false;
        errorMsg = "'Time To' should be greater than 'Time From' for "+holiday.displayDate;
        validationError.message = errorMsg;
        validationError.timeFromHasError = true;
        validationError.timeToHasError = true;
        return validationError;
      }

       var timeFromValid=/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(t.timeFrom);
       if(!timeFromValid){
        t.isValid = false;
        errorMsg = "'Time From' is not valid for "+holiday.displayDate;
        validationError.message = errorMsg;
        validationError.timeFromHasError = true;
        validationError.timeToHasError = false;
        return validationError;
       }

       var timeToValid=/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(t.timeFrom);
       if(!timeToValid){
        t.isValid = false;
        errorMsg = "'Time To' is not valid for "+holiday.displayDate;
        validationError.message = errorMsg;
        validationError.timeFromHasError = false;
        validationError.timeToHasError = true;
        return validationError;
       }
    }


    return { date: '' };
  }

  checkTimeValidity(time: string): boolean {
    // Use moment to create a date object from the time string
    const timeObj = moment(time, 'HH:mm:ss', true);
  
    // If the date object is valid, return true
    if (timeObj.isValid()) {
      return true;
    }
  
    // If the date object is not valid, return false
    return false;
  }

  getNewValidationError(): any {
    return
  }

  actionSave(): void {
    
    this.saveStatus = true;
    this.saving = true;

    if(this.selectedShopList[0] !== 0){
      this.holidayType =2;
    }

    this.deptValidationError={date:''};
    if (this.holidayTitle == undefined || this.holidayTitle.trim() == "") {
      this.deptValidationError.message="Please enter the title"
      let snackBarData: SnackBarData = {
        title: 'Failed!!!',
        type: 'error',
        message: 'Title is missing'
      };
      this.snackBarService.openSnackBar(snackBarData);
      this.saveStatus = false;
      this.saving = false;
      return;
    }

    for (var index = 0; index < this.deptFestiveTimings.length; index++) {
      var dft = this.deptFestiveTimings[index];
      this.deptValidationError = this.validateFestiveHolidays(dft);
      if (this.deptValidationError.date != "") {
        let snackBarData: SnackBarData = {
          title: 'Failed!!!',
          type: 'error',
          message: 'Department time error!'
        };
        this.snackBarService.openSnackBar(snackBarData);
       // this.toastrService.error(this.deptValidationError.message, "Department time error!");
        this.saveStatus = false;
        this.saving = false;
        return;
      }
      // dft.holidayType = (dft.holidayType == undefined || dft.holidayType == 0) ? this.holidayType : dft.holidayType;
      // dft.description = ((dft.description == undefined || dft.description == null) ? this.holidayTitle : dft.description);
    }
    // Validate time slots.
    this.serviceValidationError=this.validateTimeSlot();
    if (this.serviceValidationError.date != "") {
       let snackBarData: SnackBarData = {
          title: 'Failed!!!',
          type: 'error',
          message: 'Service Time slot error!'
        };
        this.snackBarService.openSnackBar(snackBarData);
     // this.toastrService.error(this.serviceValidationError.message, "Service Time slot error!");
      this.saveStatus = false;
      this.saving = false;
      return;
    }
    if(this.holidayType==1){
      var ans= confirm("This will replace all the shop specific calendar settings for the selected date range. Do you want to continue?");
      if(ans===false){
        this.saveStatus = false;
        this.saving = false;
        return;
      }
    }
    if(this.deptFestiveTimings[0].shopId == 0 && (this.selectedShopList[0] != 0)){
      var ans= confirm("This will replace the shop specific calendar settings for the selected date range. Do you want to continue?");
      if(ans===false){
        this.saveStatus = false;
        this.saving = false;
        return;
      }
    }

    this.deptFestiveTimings.forEach((dft:any) => {
      dft.holidayType = ((this.deptFestiveTimings[0].shopId == 0 && (this.selectedShopList[0] == 0)) )?1:2;
      dft.description = this.holidayTitle;
    });

    let shopsHolidaysList: any[]=[];
  
    /**
     * JSON.parse(JSON.stringify(this.festiveHolidays)) 
     * this line is for cloneing the festive holiday object
     */
    this.selectedShopList.forEach((sId:any) =>{
      var fhd = JSON.parse(JSON.stringify(this.festiveHolidays));
      fhd.departmentInfo.forEach((dptInfo:any) => {
        dptInfo.shopId = sId;
      });
      fhd.serviceInfo.forEach((srvInfo:any) => {
        srvInfo.shopId = sId;
      });
      shopsHolidaysList.push({shopId:sId,festiveHolidays:fhd});
    });

//this.festiveHolidays
  
    this.holidayService.holidaySaveNew(shopsHolidaysList).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.saveStatus = false;
          this.saving = false;
          this.dialogRef.close();
          let snackBarData: SnackBarData = {
            type: 'success',
            title: 'Saved',
            message: 'Calendar updated'
          };
          this.snackBarService.openSnackBar(snackBarData);
         // this.toastrService.success("Calendar updated", "Success");
          this.reloadData();
        } else {     

          this.saveStatus = false;
          this.saving = false;
          let snackBarData: SnackBarData = {
            title: 'Failed!!!',
            type: 'error',
            message: 'Failed update calendar'
          };
          this.snackBarService.openSnackBar(snackBarData);

        
        }
      },
      (error: any) => { }
    );

  }
  serviceChanged(service:any): void {
    this.selectedService = service;
  }
}

@Component({
    selector: "app-holiday",
    templateUrl: "./holiday.component.html",
    styleUrls: ["./holiday.component.scss"],
    standalone: false
})
export class HolidayComponent implements OnInit {
  title: string = "calendar";
  public date: Date;
  public dateToDisplay: any;
  public firstDay: any;
  public lastDay: any;
  public firstDayNumber: any;
  public lastDayNumber: any;
  public activeMonth: any;
  public activeYear: any;
  public typeOfHoliday: any = "1";
  public selectedShop: any;
  public selectedRegion: any;

 
  public selectedRegionShop: any;
  public daysLabel: any = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  public shopListProvider: any;
  public regionListProvider:any;
  public regionShopListProvider: any[];
  public selectedRegionShops:any;

  public allCheck:boolean = true;
  regionShopsCtrl = new FormControl();

  public loadingCalendar: boolean = true;
 
  @ViewChild("regionInput") regionInput! :  ElementRef<HTMLInputElement>;
  @ViewChild("shopInput") shopInput! :  ElementRef<HTMLInputElement>;
  public monthArrays: any = [];
  public dummyDaysPrepend: any = [];
  public holidayListProvider: any;
  public pageTitle: any;
  calanderInfo: any;
  filteredRegion: any;
  filteredShop: any;
  constructor(public dialog: MatDialog,
    private builder: FormBuilder,
    private snackBarService: SnackBarService,
    public holidayService: HolidayService) {
    this.date = new Date();
    this.calendarGenerate();
    

    this.shopListProvider = [
      {
        id: " ",
        code: " ",
        name: " "
      }
    ];
    this.regionListProvider = [
      {
        id: " ",
        code: " ",
        name: " "
      }
    ];
    this.regionShopListProvider = [
      {
        id: " ",
        code: " ",
        name: " "
      }
    ];
   

  }

  ngOnInit() {
  //  this.allSelectedModel=true;
    this.getShopList();
    this.getRegionList();
    this.loadDateModel();
  }

  previousMonth(): void {
    this.loadingCalendar = true;
    this.date.setDate(1);
    this.date.setMonth(this.date.getMonth() - 1);
    this.calendarGenerate();
    this.loadDateModel();
  }

  nextMonth(): void {
    this.loadingCalendar = true;
    this.date.setDate(1);
    this.date.setMonth(this.date.getMonth() + 1);
    this.calendarGenerate();
    this.loadDateModel();
  }

  // actonOpenDay(day: number): void {
  //   this.date.setDate(day);
  //   const dialogConfig = new MatDialogConfig();
  //   // dialogConfig.disableClose = true;
  //   dialogConfig.maxWidth = "100%";
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = "675px";
  //   dialogConfig.data = { 'date': this.date, 'holidayTpe': this.typeOfHoliday, 'selectedShop':this.selectedShop };
  //   console.log(dialogConfig);
  //   const dialogRef = this.dialog.open(HolidayEditPopUp, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => { });
  // }

  calendarGenerate(): void {
    this.firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.lastDay = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      0
    );
    this.firstDayNumber = this.firstDay.getDay();
    this.lastDayNumber = this.lastDay.getDay();
    this.monthArrays = [];
    for (let i = 1; i <= this.lastDay.getDate(); i++) {
      const fullDATE =
        this.firstDay.getFullYear() +
        "-" +
        this.appendZero(this.firstDay.getMonth() + 1) +
        "-" +
        this.appendZero(i);
      const datatoInsert = {
        date: fullDATE
      };
      this.monthArrays.push(datatoInsert);
    }
    console.log(this.monthArrays);
    this.dummyDaysPrepend = [];
    for (let i = 0; i < this.firstDayNumber; i++) {
      this.dummyDaysPrepend.push(i);
    }
    this.activeMonth = this.date.getMonth();
    this.activeYear = this.date.getFullYear();
    this.calanderInfo = (this.typeOfHoliday === '3')?"Note: Calendar preview is available only when you choose Shop or National.":""; 
    // console.log(this.activeMonth);
  }

  actionEditPopup(dataInput: any): void {
    console.log(dataInput);
    // console.log(holidayDate);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "100%";
    dialogConfig.width = "800px";
    dialogConfig.height = "710px";
    dialogConfig.panelClass = 'festive-day-edit-popup';

    dialogConfig.data = {
      day: dataInput,
      is_new: dataInput.holidayType === undefined ? true : false,
      holidayType: dataInput.holidayType === undefined ? this.typeOfHoliday : dataInput.holidayType
    };

    let selectedShopList: any=[];
    if(this.typeOfHoliday === '3'){
      if((this.allCheck == false) && (this.regionShopsCtrl.value == null)){
       // this.toastrService.info("Message", "Please select a shop");
        let snackBarData: SnackBarData = {
          message: "Please select a shop",
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
            return;
      }

      selectedShopList.shops = (this.allCheck == true)? this.regionShopListProvider.map(rs => rs.shopId):this.regionShopsCtrl.value.map((s:any) => s.shopId);
      selectedShopList.name = (this.allCheck == true)?this.regionShopListProvider[0].name : this.regionShopsCtrl.value[0].name;
    }else
    if(this.typeOfHoliday === '2'){     
      let shops: any[] = [this.selectedShop.shopId]; 
      selectedShopList.shops=shops;
      selectedShopList.name = this.selectedShop.name;
    }else
    if(this.typeOfHoliday === '1'){
      let shops: any[] = [0]; 
      selectedShopList.shops=shops;
      selectedShopList.name ='National';
    }
     


    dialogConfig.data.selectedShop = this.selectedShop;
    dialogConfig.data.selectedShopList = selectedShopList;
    
    const dialogRef = this.dialog.open(HolidayEditPopUp, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.calendarGenerate();
      this.loadDateModel();
    });
   
  }

  appendZero(input: number): any {
    let inputString = input.toString();
    if (inputString.length === 1) {
      inputString = "0" + inputString;
    }
    return inputString;
  }

  changeHolidayType(): void {
    this.calendarGenerate();
    this.loadingCalendar = true;
    if (this.typeOfHoliday === "1") {
      this.selectedShop = undefined;
      this.selectedRegion = undefined;
      this.selectedRegionShop = undefined;
    } else {
      if (this.selectedShop === undefined) {
        // this.shopId = this.shopListProvider[0].id;
        this.selectedShop = this.shopListProvider[0];
      }
      if (this.selectedRegion === undefined) {
        // this.shopId = this.shopListProvider[0].id;
        this.selectedRegion = this.regionListProvider[0];
      }
      if(this.selectedRegionShop === undefined && this.typeOfHoliday === "3"){
       
        this.regViseShop();
       
      }
    }
    this.loadDateModel();
  }

  // regShopClick(shopId): any{

  // }

  // allCheckBoxCheck(){

  //   this.allCheck = ! this.allCheck;
  //   this.regionShopsCtrl = new FormControl();

  // }
  allCheckBoxCheck(event: MatSelectChange) {
    this.allCheck = event.value;
    if (this.allCheck) {
      this.regionShopsCtrl = new FormControl();
    }
  }
  

  regViseShop(): void{
    let currentRegionId = this.selectedRegion.id;
     this.regionShopListProvider = this.shopListProvider.filter((e:any) => e.regionId == currentRegionId);
    // this.regionShopListProvider[0];
    this.selectedShop = undefined;
     this.allCheck = true;
  }

  changeRegionViseShop(): void {
   this.regViseShop();
   this.loadDateModel();
    }

  getShopList(): void {
    this.holidayService.getShopList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.shopListProvider = response.data;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getRegionList(): void {
    this.holidayService.getRegionList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          const region = response.data;
          this.regionListProvider = region.filter((e:any) => e.isValid !== 0);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  loadDateModel(): void {
    this.loadingCalendar = true;
    
    let bodyparam = {
      holidayType: this.typeOfHoliday === "1" ? [1] : [1, 2],
      dateFrom:
        this.firstDay.getFullYear() +
        "-" +
        this.convertTo2digit(this.firstDay.getMonth() + 1) +
        "-" +
        this.convertTo2digit(this.firstDay.getDate()),
      dateTo:
        this.lastDay.getFullYear() +
        "-" +
        this.convertTo2digit(this.lastDay.getMonth() + 1) +
        "-" +
        this.convertTo2digit(this.lastDay.getDate()),
      shopId: this.typeOfHoliday === "3" ? 0 : "",
      regionId:"",
     // regionShopId:""
    };
   
    if (this.selectedShop !== undefined) {
      bodyparam.shopId = this.selectedShop.shopId;
    }
    if (this.selectedRegion !== undefined) {
      bodyparam.regionId = this.selectedRegion.id;
    }

    this.holidayService.holidayGetListByDate(bodyparam).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.holidayListProvider =(this.typeOfHoliday == '3')? []:response.data;        
          this.orderHolidays();
          this.loadingCalendar = false;
        }
      },
      (error: any) => { }
    );
  }

  convertTo2digit(number: Number): any {
    const numberString = number.toString();
    if (numberString.length === 1) {
      return "0" + numberString;
    } else {
      return numberString;
    }
  }

  orderHolidays(): void {
    for (let i = 0; i < this.monthArrays.length; i++) {
      for (let j = 0; j < this.holidayListProvider.length; j++) {
        if (this.monthArrays[i].date === this.holidayListProvider[j].date) {
          this.monthArrays[i] = this.holidayListProvider[j];
        }
      }
    }
    this.loadingCalendar = false;
  }

  deleteHoliday(item: any) {

    const deleteConfirm = confirm("'Are you sure you want to delete '" + item.description + "'?");
    if (deleteConfirm === true) {
      this.holidayService.deleteHoliday(item.id).subscribe(
        (response: any) => {
          if (response.successCode === 0) {
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'Calendar updated.',
            };
            this.snackBarService.openSnackBar(snckBarData);
         //   this.toastrService.success("Calendar updated", "Success");
            this.calendarGenerate();
            this.loadDateModel();

          }
        },
        (error: any) => {
          let snackBarData: SnackBarData = {
            title: 'Failed!!!',
            type: 'error',
            message: 'Failed to update calendar'
          };
          this.snackBarService.openSnackBar(snackBarData);

         // this.toastrService.success("Failed to update calendar", "Failed");
        }
      );

    } else {
      // do nothing
    }
  }
 
  
  regionFilter(): void {
    const filterValue =this.regionInput.nativeElement.value.toLowerCase();
    this. filteredRegion= this.regionListProvider.filter((cat:any) => cat.name.toLowerCase().includes(filterValue));
    if(this.filteredRegion==undefined || this. filteredRegion.length<=0){
     
    }
   
  }
  regAcDisplayText(region:any):string{
    return (region!==undefined) ?region.name:this.regionInput.nativeElement.value;
  }
  showRegionClearButton = false;
  clearRegion() {
    this.regionInput.nativeElement.value = ''; // Clear the input value
    // Optional: Reset filtered options if needed
  }
  
 
  
  
  shopFilter(): void {
    const filterValue =this.shopInput.nativeElement.value.toLowerCase();
    this. filteredShop= this.shopListProvider.filter((cat:any) => cat.name.toLowerCase().includes(filterValue));
    if(this. filteredShop==undefined || this. filteredShop.length<=0){
     
    }
    
  }
  shopAcDisplayText(shop:any):string{
    return (shop!==undefined) ?shop.name:this.shopInput.nativeElement.value;
  }


  clearShop() {
    this.shopInput.nativeElement.value = ''; // Clear the input value
  }
  // onFocus() {
  //   this.showClearButton = true;
  // }

  // onBlur() {
  //   this.showClearButton = this.selectedShop.length > 0;
  // }
}


