import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmenityModel } from '../models/amenity.model';
import { AmenityService } from '../amenity.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PermissionService } from 'src/app/auth/permission.service';


@Component({
  selector: 'app-amenity-edit',
  templateUrl: './amenity-edit.component.html',
  styleUrl: './amenity-edit.component.scss',
  animations: [
    trigger('if', [
      // ... animation states and styles
    ])
  ]
})
export class AmenityEditComponent implements DirtyCheck {
  title: string = "New Amenity";
  editForm: FormGroup;
  amenityInfo: AmenityModel = { isValid: 1, isDeleted: 0, isSystem:0 };
  isFormDirty: boolean = false;
  imagePreview: any;
  permission: any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private amenityService: AmenityService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private permissionService: PermissionService,) {

    this.permission = permissionService.getPagePermission('amenity');

    let amenityId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.amenityInfo.code, [Validators.required, Validators.maxLength(50)]),
      name: this.builder.control(this.amenityInfo.name, [Validators.required]),
      smallIcon: this.builder.control(this.amenityInfo.smallIcon),
      description: this.builder.control(this.amenityInfo.description),
      displayOrder: this.builder.control(this.amenityInfo.displayOrder, [Validators.pattern('^[0-9]*$')]),
      isValid: this.builder.control(this.amenityInfo.isValid === 1 )
    });

    // Subscribe to changes in smallIcon
    this.editForm?.get('smallIcon')?.valueChanges.subscribe((value) => {
      this.imagePreview = this.getSanitizedImage(value);
    });
    

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (amenityId !== null && parseInt(amenityId) !== 0) {
      this.setamenityInfo(amenityId);
    }
  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/amenity']);

  }

  /**
   * If tas is is null set an empty amenity info
   * if amenity id is not null call api to rtrive the info
   * @param amenityId 
   */
  setamenityInfo(amenityId: any): void {

    let response = this.amenityService.getId(amenityId).subscribe({
      next: (response) => {
        if (response.successCode === 0) {
          this.amenityInfo = response.data;
          this.editForm.patchValue(this.amenityInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.amenityInfo.id == 0) ? "New Amenity" : "Editing : " + this.amenityInfo.name;
    return title;
  }
  onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imageData = e.target.result;
        this.editForm.patchValue({ smallIcon: imageData });
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(): void {
    this.editForm.patchValue({ smallIcon: '' });
  }

  getSanitizedImage(imageData: any): any {
    if (imageData) {
      return this.sanitizer.bypassSecurityTrustUrl(imageData);
    }
    return null;
  }
  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
  save(): void {
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
    }else{
      Object.assign(this.amenityInfo, this.editForm.value);

      // Assuming 1 for createdBy and updatedBy, 0 for isDeleted and isSystem
      const formValues = this.editForm.value;
      this.amenityInfo = {
        ...this.amenityInfo,
        ...formValues,
        isValid: formValues.isValid ? 1 : 0,
      };
 
      const activeUser: any = this.auth.getUser();

      if (this.amenityInfo.id === 0 || this.amenityInfo.id === undefined) {
        this.amenityInfo.createdBy = activeUser.id;
      } else {
        this.amenityInfo.updatedBy = activeUser.id;
      }

      this.amenityService.update(this.amenityInfo).subscribe({
        next: (response: any) => {
          if (response.successCode === 0) {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + (this.amenityInfo ? this.amenityInfo.name : '') + ' has been updated successfully.',
            };
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['amenity']);
          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
      });
    }
  }

  getErrorDescription(controlName: string): string | null {
    var message = null;
    const errors = this.editForm.get(controlName)?.errors;
    if (errors) {
      if (errors['required'])
        message = "This field should be entered.";
      else if (errors['maxlength'])
        message = "Max length of the code is " + errors['maxlength'].requiredLength;
    }
    return message;
  }  
}

