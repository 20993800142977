import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name: "orderStatus",
    standalone: false
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return "Order Placed";
      case 2:
        return "Sent to shop";
      case 3:
        return "Confirmed By Shop";
      case 4:
        return " Sent to kitchen";
      case 5:
        return "Under Preparation";
      case 6:
        return "Ready to Deliver";
      case 7:
        return "Delivered";
        case 8:
        return "Canceled";
      default:
        return " NA ";
    }
  }
}
